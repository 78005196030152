import React from "react";
import { Grid, Typography } from "@material-ui/core";
import GoogleMap from "../map/google-map";

const SectionLocationMap = ({
    lat,
    lng,
    zoom,
    address,
}: {
    lat: number,
    lng: number,
    zoom: number,
    address: string,
}) => (<>
    <div style={{ padding: '8px' }}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography gutterBottom component="div" variant="h6" style={{
                    fontWeight: 600,
                }}>
                    Locatie
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div style={{
                    height: '300px',
                    margin: '10px',

                }}>
                    <GoogleMap style={{height: '100%', width: '100%'}} lat={lat} lng={lng} zoom={zoom || 13} label={address} />
                </div>
                <Typography color="textSecondary" component="p" variant="body1" style={{
                    margin: '5px',
                    padding: '5px'
                }}>
                    De exacte locatie wordt na de reservering verstrekt.
                </Typography>
            </Grid>
        </Grid>
    </div>
</>);

export default SectionLocationMap;