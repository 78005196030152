import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        // Tell Material-UI what's the font-size on the html element is.
        // htmlFontSize: 10,
        fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
        fontSize: 12,
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#c17700',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#F55A58'
        }
    },
})

const themWithResponse = responsiveFontSizes(theme);

export default themWithResponse;