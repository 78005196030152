import React from "react";
import { useLocation } from "react-router-dom";

const useQuery = () => new URLSearchParams(useLocation().search);

const usePrevious = (value: any) => {
    const ref = React.useRef();
    React.useEffect(() => { ref.current = value });

    return ref.current;
}

const useLocationChange = (action: (location: any, prev: any) => void) => {
    const location = useLocation();
    const prevLocation = usePrevious(location);
    React.useEffect(() => {
        action(location, prevLocation);
    }, [location]);
}

export { useQuery, usePrevious, useLocationChange };
