/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { BookingWizardContext } from "./BookingWizard";

export interface WithWizardProps {
    step?: number,
    isFocus?: boolean,
}

export const withWizard = <P extends object>(Component: React.ComponentType<P>): React.FC<P & WithWizardProps> => ({ step, ...props}: WithWizardProps) => {
    const { currentStep } = React.useContext(BookingWizardContext);

    return step === currentStep ? <Component { ...props as P} step={step} isFocus={ step === currentStep }  /> : null;
}
