import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Divider, Tooltip } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { ICourseProduct } from "../../models/course";
import { IVoucher } from "../../models/voucherCode";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
        },
        cell: {
            border: 'none',
            padding: theme.spacing(2),
        },
        hint: {
            margin: theme.spacing(0),
            padding: theme.spacing(0),
        }
    }),
);

const SectionProducts = ({
    data = [],
    promotions = [],
    onUpdateNr = () => { },
    maxNr = 0,
}: {
    data?: (ICourseProduct & { nr: number })[],
    promotions?: IVoucher[],
    onUpdateNr?: (id: string, nr: number) => void,
    maxNr?: number,
}) => {
    const classes = useStyles();

    const total = data.reduce((a, c) => a + (c.nr * c.productPrice), 0) - promotions.reduce((a, c) => a + (c.discountAmount), 0);

    return (<>
        <TableContainer>
            <Table className={classes.table} aria-label="product detail table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.cell}>&nbsp;</TableCell>
                        <TableCell className={classes.cell} align="right">Aantal</TableCell>
                        <TableCell className={classes.cell} align="right">Prijs</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(item => <TableRow key={item.productId}>
                        <TableCell className={classes.cell} scope="row">
                            <div style={{
                                display: 'flex',
                            }}>
                                <Typography component="div" style={{
                                    fontWeight: 600,
                                    marginRight: '4px'
                                }}>
                                    {item.productName}
                                </Typography>
                                <Tooltip title={item.productDescription}>
                                    <IconButton className={classes.hint} aria-label="product-description">
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                            </div>

                        </TableCell>
                        <TableCell className={classes.cell}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}>
                                {item.isAddon
                                    ? <IconButton aria-label="delete" style={{ padding: 0 }} onClick={() => {
                                        onUpdateNr(item.productId, item.nr - 1)
                                    }} >
                                        <RemoveIcon fontSize='small' />
                                    </IconButton>
                                    : <div style={{ display: 'block', width: '20px', height: '20px' }}>{' '}</div>
                                }
                                <Typography style={{ marginLeft: '5px', marginRight: '5px' }}>{item.nr}</Typography>
                                {item.isAddon
                                    ? <IconButton aria-label="add" style={{ padding: 0 }} onClick={() => (item.nr < maxNr) && onUpdateNr(item.productId, item.nr + 1)} >
                                        <AddIcon fontSize='small' />
                                    </IconButton>
                                    : <div style={{ display: 'block', width: '20px', height: '20px' }}>{` `}</div>
                                }

                            </div>
                        </TableCell>
                        <TableCell className={classes.cell} align="right">
                            <Typography>{`\u20AC${item.productPrice * item.nr}`}</Typography>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
        <Divider light />
        <Typography component="div"
            style={{
                fontWeight: 600,
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px'
            }}
        >
            <div> Totaal </div>
            <div>{`\u20AC${total < 0 ? 0 : total}`}</div>
        </Typography>
    </>)
}

export default SectionProducts;