import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider, CardMedia, Button, Grid, Theme } from '@material-ui/core';
import moment from 'moment';

import { ISearchResultData } from '../../models/search';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        container: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(2),

        },
        main: {
            display: 'inline-block', verticalAlign: 'top', width: '100%',
            cursor: 'pointer'
        },
        group: {
            height: '100%', position: 'relative', zIndex: 1
        },
        media: {
            backgroundSize: 'cover',
            width: '300px',
            height: '200px',
            // backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'center',
            borderRadius: '10px',
        },
        left: {
            padding: theme.spacing(0.5), display: 'flex', flexDirection: 'column'
        },
        priceInfoRow: {
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'
        },
        buttonRoot: {
            '&$disabled': {
                backgroundColor: theme.palette.secondary.main,
                color: 'white',
            },
        },
        disabled: {},
    }),
);

const SeparateLine = () => <Divider light style={{ marginTop: '12px', marginBottom: '24px' }} />;

const CourseOverviewItem = ({ isFirst = true, item, onSelect = () => { } }: {
    item: ISearchResultData,
    isFirst?: boolean,
    onSelect?: () => void,
}) => {
    const classes = useStyles();

    const { id, course: { name: courseName }, location: { name: locationName, city }, occurances, availableSlots, basePrice } = item;

    return (<div key={id} className={classes.root}>
        <div className={classes.container}>
            <div role="presentation" className={classes.main} onClick={() => availableSlots > 0 && onSelect()}>
                {isFirst && <SeparateLine />}
                <Grid container spacing={2}>
                    <Grid item>
                        <CardMedia
                            className={classes.media}
                            image={`${item.course.imageURL}`}
                        />
                    </Grid>
                    <Grid item xs={12} sm className={classes.left} style={{ flex: 1 }}>
                        <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Box style={{ display: 'flex', flex: 1, flexDirection: 'column', marginRight: '16px' }}>
                                <Typography component="div" variant='subtitle1' color='textSecondary'>
                                    <div>{locationName} in {city}</div>
                                </Typography>
                                <Typography component="div" variant="h6">
                                    <div>{courseName}</div>
                                </Typography>
                            </Box>
                        </Box>
                        <Divider light style={{ width: '32px', marginTop: '12px' }} />
                        {occurances.sort((a, b) => a.start.localeCompare(b.start)).map((d) => {
                            const start = moment(d.start).locale('fr');
                            const end = moment(d.end).locale('fr');

                            return (<Typography key={`${id}-${d.start}`} component="div" variant="subtitle1" color='textSecondary' style={{ marginTop: '4px' }}>
                                {`${start.format('dddd Do MMM')} - ${start.format('HH:mm')} uur -${end.format('HH:mm')} uur`}
                            </Typography>)
                        })}

                        <Box className={classes.priceInfoRow} style={{ flex: 1 }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                                <Typography component='div' variant="h6" style={{ fontWeight: 'bold' }}>
                                    {`\u20AC${basePrice}`}
                                </Typography>

                                <Typography component='div' variant="h6" color='textSecondary' style={{
                                    marginLeft: '2px',
                                    marginRight: '2px',
                                }}>
                                    /
                                </Typography>

                                <Typography component='div' variant="h6" color='textSecondary'>
                                    deelnemer
                                </Typography>
                            </div>
                            <Button
                                classes={{ 
                                    root: classes.buttonRoot, // class name, e.g. `root-x`
                                    disabled: classes.disabled, // class name, e.g. `disabled-x`
                                 }}
                                color="primary"
                                variant="contained"
                                size="small"
                                disabled={availableSlots <= 0}
                            >
                                {availableSlots > 1
                                    ? `Nog ${availableSlots} plekken beschikbaar!`
                                    : `Nog ${availableSlots} plek beschikbaar!`
                                }
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <SeparateLine />
            </div>
        </div>
    </div>);
}

export default CourseOverviewItem;