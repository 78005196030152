/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

interface Props {
    style?: React.CSSProperties,
    children?: React.ReactElement[] | React.ReactElement,
}


export const Wrapper = ({ style, children, ...rest }: Props) => {
    const childrenWithProps = React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {...rest});
        }
        return child;
    });

    return <div style={style || {}}>
        {childrenWithProps}
    </div>
}

export const withWrapper = <P extends object>(
    Component: React.ComponentType<P>,
    style?: React.CSSProperties
) => (props: P) => (
        <Wrapper style={style || {}}>
            <Component {...props} />
        </Wrapper>
    )