import React from 'react';

const EmptySpace = ({ width, height= '5px' }: {
    width?: string | number,
    height?: string | number,
}) => (
    <div style={{
        display: 'block',
        width,
        height,
    }}>{` `}</div>
)

export default EmptySpace;