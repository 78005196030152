import React from 'react';

type Props = {
    children?: React.ReactElement[] | React.ReactElement,
    noSticky?: boolean,
}

const StickyFooter = ({ children, noSticky = false }: Props) => (<>
    {!noSticky 
        && <div style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            zIndex: 99
        }}>
            {children}
        </div>
    }

    {noSticky 
        && <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            {children}
        </div>
    }
</>)

export default StickyFooter;