import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, MenuItem, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Reservation } from '../../models/reservation';
import { ICountry } from '../../models/country';
import StickyFooter from '../StickyFooter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        inputRoot: {
            // fontSize: '16px'
        },
        labelRoot: {
            "&$labelFocused": {
                color: theme.palette.primary
            }
        },
        labelFocused: {},
        row: {
            display: 'flex',
            justifyContent: 'stretch'
        },
        button: {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '10px',
            margin: '5px',
            flex: 1
        },
    }),
);

const validationSchema = (action: 'new' | 'update') => {
    if (action === 'new') {
        return yup.object({
            salutation: yup.string().required("Aanhef is verplicht"),
            initial: yup.string().required("Voorletters is verplicht"),
            firstName: yup.string().required("Voornaam is verplicht"),
            lastName: yup.string().required("Achternaam is verplicht"),
            birthDay: yup.date().nullable().required("Geboortedatum is verplicht"),
            countryId: yup.string().required("Land is verplicht"),
            addrStreet: yup.string().required("Adres is verplicht"),
            addrHouseNumber: yup.string().required("Huisnummer is verplicht"),
            addrZipCode: yup.string().required("Postcode is verplicht"),
            city: yup.string().required("Plaats is verplicht"),
            email: yup.string().email('Vul een geldig e-mailadres in').required('Email is verplicht'),
            confirmEmail: yup.string().when('email', {
                is: (val: string) => (!!(val && val.length > 0)),
                then: yup.string().oneOf(
                    [yup.ref('email')], 'Controleveld is onjuist ingevuld'
                )
            }).required('Confirm Email is verplicht'),
            phone: yup.string().required("Telefoon is verplicht"),
        });
    }

    if (action === 'update') {
        return yup.object({
            salutation: yup.string().required("Aanhef is verplicht"),
            initial: yup.string().required("Voorletters is verplicht"),
            firstName: yup.string().required("Voornaam is verplicht"),
            lastName: yup.string().required("Achternaam is verplicht"),
            birthDay: yup.date().nullable().required("Geboortedatum is verplicht"),
            countryId: yup.string().required("Land is verplicht"),
            addrStreet: yup.string().required("Adres is verplicht"),
            addrHouseNumber: yup.string().required("Huisnummer is verplicht"),
            addrZipCode: yup.string().required("Postcode is verplicht"),
            city: yup.string().required("Plaats is verplicht"),
            email: yup.string().email('Vul een geldig e-mailadres in').required('Email is verplicht'),
            phone: yup.string().required("Telefoon is verplicht"),
        });
    }

    throw new Error(`action is invalid, ${action}`);
};

export type DetailFormModel = Reservation & { confirmEmail: string };


const ParticipantDetailForm = ({
    data,
    countrySource = [],
    existingEmails = [],
    action = 'new',
    canPrevious = true,
    isFinished = false,
    onSubmit = () => { },
    onCancel = () => { },
}: {
    data?: Reservation,
    countrySource?: ICountry[],
    existingEmails?: string[],
    action?: 'new' | 'update',
    canPrevious?: boolean,
    isFinished?: boolean,
    onSubmit?: (data: Reservation) => void,
    onCancel?: () => void,
}) => {
    const classes = useStyles();

    const handleSubmit = (values: DetailFormModel) => {
        const { confirmEmail, ...post } = values;
        onSubmit(post as Reservation);
    }

    const formik = useFormik<DetailFormModel>({
        initialValues: data as DetailFormModel,
        validationSchema: validationSchema(action),
        enableReinitialize: true,
        onSubmit: handleSubmit
    });

    return (<>
        <form onSubmit={formik.handleSubmit}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                }}>
                    <div className={classes.row}>
                        <FormControl
                            component="fieldset"
                            className={classes.textField}
                        >
                            <FormLabel
                                component="legend"
                                className={classes.labelRoot}
                            >
                                Aanhef
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-label="salutation"
                                id="salutation"
                                name="salutation"
                                defaultValue="man"
                                value={formik.values.salutation}
                                onChange={formik.handleChange}
                            >
                                <FormControlLabel value="man" control={<Radio color="primary" />} label="Dhr" />
                                <FormControlLabel value="woman" control={<Radio color="primary" />} label="Mevr" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className={classes.row}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="initial"
                            name="initial"
                            label="Voorletters"
                            value={formik.values.initial || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.initial && Boolean(formik.errors.initial)}
                            helperText={formik.touched.initial && formik.errors.initial} />
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="firstName"
                            name="firstName"
                            label="Voornaam"
                            value={formik.values.firstName || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName} />
                    </div>

                    <div className={classes.row}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="middleName"
                            name="middleName"
                            label="Tussenvoegsels"
                            value={formik.values.middleName || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                    </div>

                    <div className={classes.row}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="lastName"
                            name="lastName"
                            label="Achternaam"
                            value={formik.values.lastName || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName} />
                    </div>
                    <div className={classes.row}>
                        <DatePicker
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}
                            disableFuture
                            openTo="year"
                            format="DD-MMM-YYYY"
                            label="Geboortedatum"
                            views={["year", "month", "date"]}
                            value={formik.values.birthDay || null}
                            onChange={(date) => formik.setFieldValue('birthDay', date?.toDate())}
                            onBlur={formik.handleBlur}
                            error={(formik.dirty || formik.touched.birthDay) && Boolean(formik.errors.birthDay)}
                            helperText={(formik.dirty || formik.touched.birthDay) && formik.errors.birthDay}
                        />

                    </div>
                    <div className={classes.row}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="countryId"
                            name="countryId"
                            label="Land"
                            select
                            value={formik.values.countryId || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
                            helperText={formik.touched.countryId && formik.errors.countryId}
                        >
                            {countrySource.map(c => <MenuItem key={c.id} value={c.id}>
                                {c.name}
                            </MenuItem>)}
                        </TextField>
                    </div>

                    <div className={classes.row}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="addrStreet"
                            name="addrStreet"
                            label="Adres"
                            value={formik.values.addrStreet || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.addrStreet && Boolean(formik.errors.addrStreet)}
                            helperText={formik.touched.addrStreet && formik.errors.addrStreet} />
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="addrHouseNumber"
                            name="addrHouseNumber"
                            label="Huisnummer"
                            value={formik.values.addrHouseNumber || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.addrHouseNumber && Boolean(formik.errors.addrHouseNumber)}
                            helperText={formik.touched.addrHouseNumber && formik.errors.addrHouseNumber} />
                        <TextField
                                className={classes.textField}
                                fullWidth
                                margin="dense"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                    }
                                }}

                                id="addrNumberAddition"
                                name="addrNumberAddition"
                                label="Toevoeging"
                                value={formik.values.addrNumberAddition || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur} />
                    </div>

                    <div className={classes.row}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="addrZipCode"
                            name="addrZipCode"
                            label="Postcode"
                            value={formik.values.addrZipCode || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.addrZipCode && Boolean(formik.errors.addrZipCode)}
                            helperText={formik.touched.addrZipCode && formik.errors.addrZipCode} />
                    </div>

                    <div className={classes.row}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="city"
                            name="city"
                            label="Plaats"
                            value={formik.values.city || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city} />
                    </div>

                    <div className={classes.row}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="email"
                            name="email"
                            label="E-mailadres"
                            value={formik.values.email || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={(formik.touched.email && Boolean(formik.errors.email)) || (formik.values.email ? existingEmails.includes(formik.values.email) : false)}
                            helperText={(formik.touched.email && formik.errors.email) || (formik.values.email && existingEmails.includes(formik.values.email) && `Dit e-mailadres is reeds bij ons ingevoerd, voer een ander e-mailadres in of neem contact met ons op.`)} />
                    </div>
                    {
                        action === 'new' &&
                        <div className={classes.row}>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                margin="dense"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                    }
                                }}

                                id="confirmEmail"
                                name="confirmEmail"
                                label="E-mailadres bevestigen"
                                value={formik.values.confirmEmail || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.confirmEmail && Boolean(formik.errors.confirmEmail)}
                                helperText={formik.touched.confirmEmail && formik.errors.confirmEmail} />
                        </div>
                    }


                    <div className={classes.row}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}

                            id="phone"
                            name="phone"
                            label="Telefoon"
                            value={formik.values.phone || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone} />
                    </div>
                </div>
                <StickyFooter>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'stretch',
                        alignItems: 'stretch'
                    }}>
                        <Button
                            disabled={!canPrevious}
                            onClick={onCancel}
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            style={{
                                textDecorationLine: 'underline'
                            }}
                        >
                            Vorige
                        </Button>
                        <Button
                            type="submit"
                            // disabled={action === 'new') || !formik.isValid}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                        >
                            {isFinished ? `Afronden` : `Volgende`}
                        </Button>
                    </div>
                </StickyFooter>
            </MuiPickersUtilsProvider>
        </form>
    </>);
}

export default ParticipantDetailForm;