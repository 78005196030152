import { Reducer } from 'redux';
import * as MODEL from './model';

const reducer: Reducer<MODEL.IState> = (state: MODEL.IState = MODEL.initialState, action: MODEL.Actions) => {
    switch(action.type) {
        case MODEL.ActionType.SHOW_MESSAGE:
            return {
                ...state,
                count: state.count - 1,
                list: [...state.list, action.payload]
            }
        case MODEL.ActionType.REMOVE_MESSAGE:
            return {
                ...state,
                list: state.list.filter(i => i.id === action.payload)
            }
        case MODEL.ActionType.CLEAR_ALL:
            return {
                ...state,
                list: [],
            }
        default:
            return state;
    }
}

export default reducer;
