import React from 'react';
import { useHistory } from "react-router-dom";
import { Box, Button, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { BookingWizardContext } from '../wizard/BookingWizard';
import StickyHeader from '../StickyHeader';
import StickyFooter from '../StickyFooter';
import CourseOverViewCourseInfo from './CourseOverViewCourseInfo';
import { useReservationContext } from '../../store';
import SectionProgram from './SectionProgram';
import SectionConditions from './SectionConditions';
import SectionSecondaryInfo from './SectionSecondaryInfo';
import SectionLocationMap from './SectionLocationMap';
import { withWizard } from '../wizard/withWizard';
import EmptySpace from '../layout/EmptySpace';

const CourseOverview = () => {
    const history = useHistory();

    const { state: { plan: selectedCourse } } = useReservationContext();

    const { goNext } = React.useContext(BookingWizardContext);

    const handleNext = () => {
        if (goNext) {
            goNext();
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<>
        <StickyHeader
            start={<IconButton
                onClick={() => history.goBack()}
                style={{
                    padding: '5px'
                }}
            >
                <ArrowBackIosIcon style={{
                    fontSize: '20px',
                }} />
            </IconButton>}
            component={<></>}
            end={<div style={{
                display: 'flex',
            }}>
                <IconButton
                    style={{
                        padding: '5px'
                    }}
                >
                    <ShareIcon style={{
                        fontSize: '20px',
                    }} />
                </IconButton>
                <IconButton
                    style={{
                        padding: '5px'
                    }}
                >
                    <FavoriteBorderIcon style={{
                        fontSize: '20px',
                    }} />
                </IconButton>
            </div>}
        />
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box height="200px">
                    <CourseOverViewCourseInfo item={selectedCourse} />
                </Box>
                
                <Divider light />
            </Grid>
            <Grid item xs={12}>
                <SectionProgram
                    caption={selectedCourse?.course.programHeader}
                    detail={selectedCourse?.course.program}
                    logo={`${selectedCourse?.location.logoURL}`} />
                <Divider light />
            </Grid>
            <Grid item xs={12}>
                <SectionConditions data={selectedCourse?.course.conditions} />
                <Divider light />
            </Grid>

            <Grid item xs={12}>
                <SectionSecondaryInfo title={selectedCourse?.location.name} description={selectedCourse?.location.description} />
                <Divider light />
            </Grid>
            <Grid item xs={12}>
                {selectedCourse && <SectionLocationMap lat={selectedCourse.location.lat} lng={selectedCourse.location.lng} zoom={selectedCourse.location.zoom} address={selectedCourse.location.name} />}
            </Grid>
            <Grid item xs={12}>
                <EmptySpace height="40px" />
            </Grid>
        </Grid>
        <StickyFooter>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    marginLeft: '5px'
                }}>
                    <Typography component='div' variant="h5" style={{ fontWeight: 'bold' }}>
                        {`\u20AC${selectedCourse?.basePrice}`}
                    </Typography>

                    <Typography component='div' variant="h5" color='textSecondary' style={{
                        marginLeft: '2px',
                        marginRight: '2px',
                    }}>
                        /
                    </Typography>

                    <Typography component='div' variant="h5" color='textSecondary' style={{
                        fontSize: '16px',
                        color: 'grey'
                    }}>
                        deelnemer
                    </Typography>
                </div>

                <div style={{ margin: '4px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleNext()}
                    >
                        Reserveren
                    </Button>
                </div>
            </div>
        </StickyFooter>
    </>);
}

export default CourseOverview;

export const CourseOverviewStep = withWizard(CourseOverview);