import React from 'react';
import { IconButton, Button, Typography, Box, Hidden, Card, CardContent, Badge, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import TodayIcon from '@material-ui/icons/Today';
import Calendar from '../calendar/Calendar';
import { useFilterContext } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'inline-block',
            verticalAlign: 'middle',
            textAlign: 'left',
            transformOrigin: '0% 0%',
            transform: '-ms-transform 150ms ease 0s, -webkit-transform 150ms ease 0s, transform 150ms ease 0s, opacity 50ms ease 20ms, pointer-events 0ms ease 150ms',

            [theme.breakpoints.up('md')]: {
                transformOrigin: '50% 0%',
                margin: '0px auto',
            }
        },
        controls: {
            WebkitBoxAlign: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid rgb(221, 221, 221)',
            borderRadius: '24px',
            color: theme.palette.primary.dark,
            maxWidth: '100%',
            textAlignLast: 'left',
            transition: 'box-shadow 0.2s ease 0s',
            verticalAlign: 'middle',
        },
        seperator: {
            backgroundColor: 'rgb(221, 221, 221)',
            flex: '0 0 1px',
            height: '24px',
            width: '1px',
        },
        button: {
            WebkitBoxAlign: 'center',
            height: '48px',
            minWidth: '0px',
            appearance: 'none',
            background: 'transparent',
            border: '1px',
            borderRadius: '4px',
            color: 'inherit',
            display: 'flex',
            flex: '0 1 auto',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0px',
            margin: '-1px',
            userSelect: 'auto',
            zIndex: 1,
            '&:last-of-type': {
                borderTopRightRadius: 'inherit',
                borderBottomRightRadius: 'inherit',
            },
            '&:first-of-type': {
                paddingLeft: '8px',
                borderTopLeftRadius: 'inherit',
                borderBottomLeftRadius: 'inherit',
            },
        },
        buttonLabel: {
            fontSize: theme.typography.caption.fontSize,
            fontWeight: 600,
            flex: '1 1 auto',
            padding: '0 16px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        onSearchButton: {
            zIndex: 1,
            borderRadius: '50%',
            height: '48px',
            width: '48px',
            maxWidth: '48px',
            padding: '8px',
            margin: 0,
        },
        calendarButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            verticalAlign: 'middle',
            textAlign: 'left',
            transformOrigin: '0% 0%',
            transform: '-ms-transform 150ms ease 0s, -webkit-transform 150ms ease 0s, transform 150ms ease 0s, opacity 50ms ease 20ms, pointer-events 0ms ease 150ms',

            [theme.breakpoints.up('md')]: {
                transformOrigin: '50% 0%',
                margin: '0px auto',
            }
        },
        calendarButtonContainer2: {
            // display: 'inline-block',
            // verticalAlign: 'middle',
            // textAlign: 'left',
            // transformOrigin: '0% 0%',
            // transform: '-ms-transform 150ms ease 0s, -webkit-transform 150ms ease 0s, transform 150ms ease 0s, opacity 50ms ease 20ms, pointer-events 0ms ease 150ms',

            // [theme.breakpoints.up('md')]: {
            //     transformOrigin: '50% 0%',
            //     margin: '0px auto',
            // }
        },
        backdrop: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            inset: "80px 0 0 0",
            position: 'fixed',
            zIndex: 0,
        }
    }),
);

const SearchBarSmall = ({ onClick = () => { } }: { onClick?: () => void }) => {
    const classes = useStyles();

    return (<div className={classes.root}>
        <Hidden smDown>
            <div className={classes.controls} role='search'>
                <Button className={classes.button} type="button" onClick={onClick}>
                    <Typography className={classes.buttonLabel} component='div'>Locaties</Typography>
                </Button>
                <Box className={classes.seperator} role='seperator' />
                <Button className={classes.button} type="button" onClick={onClick}>
                    <Typography className={classes.buttonLabel} component='div'>Cursus</Typography>
                </Button>
                <Box className={classes.seperator} role='seperator' />
                <Button className={classes.button} type="button" onClick={onClick}>
                    <Typography className={classes.buttonLabel} component='div'>Deelnemers</Typography>
                    <IconButton color="primary" aria-label="upload picture" component="span" size="medium">
                        <Search />
                    </IconButton>
                </Button>
            </div>
        </Hidden>
    </div>);
}

export const SmallMenu = ({
    expansed = false,
    onExpanseClick = () => { },
    onColapseClick = () => { },
    onHomeClick = () => { },
    onLocationClick = () => { },
    onCourseClick = () => { },
    onGuestClick = () => { },
}: {
    expansed?: boolean,
    onExpanseClick?: () => void,
    onColapseClick?: () => void,
    onHomeClick?: () => void,
    onLocationClick?: () => void,
    onCourseClick?: () => void,
    onGuestClick?: () => void
}) => (
        <Hidden mdUp>
            { expansed && <>
                <Button color="primary" style={{ zIndex: 1, }} onClick={onHomeClick}>
                    <HomeIcon />
                </Button>
                <Button color="primary" style={{ zIndex: 1, }} onClick={onLocationClick}>Locaties</Button>
                <Button color="primary" style={{ zIndex: 1, }} onClick={onCourseClick}>Cursus</Button>
                <Button color="primary" style={{ zIndex: 1, }} onClick={onGuestClick}>Deelnemers</Button>
            </>
            }
            <IconButton color="primary" component="span" size="medium" style={{ zIndex: 1, }} onClick={() => expansed ? onColapseClick() : onExpanseClick()}>
                {!expansed ? <Search fontSize='large' /> : <CloseIcon fontSize='large' />}
            </IconButton>

        </Hidden>
    )


export const CalendarItem = ({
    onShowCalendar = () => { }
}: {
    onShowCalendar?: () => void,
}) => {
    const classes = useStyles();

    const [expansed, setExpansed] = React.useState(false);

    const { state: { result, extra: { filterDate }, filter }, setExtraFilterDate, removeExtraFilterDate, toggleShowOnlyAvaliableOption } = useFilterContext();

    const showCalendar = () => {
        if (!expansed) {
            onShowCalendar();
        }

        setExpansed(!expansed);
    }

    return (<Box className={classes.calendarButtonContainer}>
        <Button color='primary' style={{ zIndex: 1 }} onClick={showCalendar}>
            <Badge color="secondary" variant='dot' invisible={!filterDate}>
                {!expansed
                    ? <TodayIcon fontSize='large' />
                    : <CloseIcon fontSize='large' />
                }
            </Badge>
        </Button>
        { expansed &&
            <>
                <div role="presentation" className={classes.backdrop} onClick={showCalendar}>{` `}</div>
                <Box style={{
                    position: 'absolute',
                    top: '80px',
                    left: '-40px'
                }}>
                    <Card style={{ borderRadius: '10px' }}>
                        <CardContent>
                            <Box>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Switch name="showOnlyAvaiable" checked={filter.showOnlyAvailableSlot} onChange={toggleShowOnlyAvaliableOption} />}
                                        label="Laat alleen beschikbare cursussen zien"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </Box>
                            <Calendar
                                value={filterDate}
                                markedDates={result.availableDates}
                                onSelectDate={date => setExtraFilterDate(date)}
                            />
                            {filterDate && <Button color='primary' onClick={() => removeExtraFilterDate()} >Bekijk alle beschikbare datums</Button>}
                        </CardContent>
                    </Card>
                </Box>
            </>

        }
    </Box>);
}

export default SearchBarSmall;