import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import { ISearchResultData } from '../../models/search';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        coverRoot: {
            height: '100%',
            display: 'block',
            overflow: 'hidden',
            position: 'relative',
        },

        coverImage: {
            left: '50%',
            width: '100%',
            height: '100%',
            position: 'relative',
            transform: 'translateX(-50%)',
            objectFit: 'cover'
        },

        coverSubtileRoot: {
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            alignItems: 'center',
            minHeight: '68px',
        },

        coverSubtileWrapper: {
            color: 'white',
            overflow: 'hidden',
            flexGrow: 1,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        }

    }),
);

const Mobile = ({ item = ({} as ISearchResultData) }: {
    item?: ISearchResultData
}) => {
    const classes = useStyles();
    const { course: { name: courseName }, location: { name: locationName }, occurances } = item;

    return (<div className={classes.coverRoot}>
        <img className={classes.coverImage} src={`${item.course.imageURL}`} alt='logo' />
        <div className={classes.coverSubtileRoot}>
            <div className={classes.coverSubtileWrapper}>
                <Typography>{`${locationName}`} </Typography>
                <Typography>{`${courseName}`}</Typography>
                {occurances.sort((a, b) => a.start.localeCompare(b.start)).map((d) => {
                    const start = moment(d.start).locale('fr');
                    const end = moment(d.end).locale('fr');

                    return (<Typography key={`${item.id}-${d.start}`}>
                        {`${start.format('dddd Do MMM')} - ${start.format('HH:mm')} uur -${end.format('HH:mm')} uur`}
                    </Typography>)
                })}
            </div>
        </div>
    </div>);
}

const Desktop = ({ item = ({} as ISearchResultData) }: {
    item?: ISearchResultData
}) => {
    const classes = useStyles();
    const { course: { name: courseName }, location: { name: locationName } } = item;

    return (<div className={classes.coverRoot}>
        <img className={classes.coverImage} src={`${item.course.imageURL}`} alt='logo' />
        <div className={classes.coverSubtileRoot}>
            <div className={classes.coverSubtileWrapper}>
                <Typography>{`${locationName}`}</Typography>
                <Typography>{`${courseName}`}</Typography>
            </div>
        </div>
    </div>);
}

export default Mobile;

export {
    Desktop,
}