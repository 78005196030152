import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

import * as APIService from '../../api/golfbooking.service';
import { ICountry } from '../../models/country';
import { Reservation, getDefaultReservation } from '../../models/reservation';
import { useReservationContext } from '../../store';
import ParticipantDetailForm from './ParticipantDetailForm';
import { useDevice } from '../../hooks/device';
import LayoutDesktop from '../layout/Layout.desktop';
import LayoutMobile, { WithLayoutMobileProps } from '../layout/Layout.mobile';
import { PageLoadingContext } from '../layout/LoadingIndicator';
import EmptySpace from '../layout/EmptySpace';
import { ICourseProduct } from '../../models/course';

const FillParticipants = ({
    dataArray = [],
    total,
    countrySource = [],
    addons,
    onAdded = () => { },
    onUpdated = () => { },
    onFinished = () => { },
    containerStyle,
    setTitle = () => { },
}: {
    dataArray: Reservation[],
    total: number,
    countrySource: ICountry[],
    addons: (ICourseProduct & { nr: number })[],
    onAdded?: (data: Reservation) => void,
    onUpdated?: (data: Reservation) => void,
    onFinished?: () => void,
    containerStyle?: React.CSSProperties,
} & Partial<WithLayoutMobileProps>) => {

    const [index, setIndex] = React.useState(0);

    const handleSubmit = (action: 'new' | 'update') => (data: Reservation) => {

        if (action === 'new') onAdded(data);
        if (action === 'update') onUpdated(data);

        setIndex(c => c < total - 1 ? c + 1 : c);

        if (index === total - 1) {
            onFinished();
        }
    }

    React.useEffect(() => {
        setTitle(`Deelnemer ${index + 1} / ${total}`)
    }, [index, setTitle]);

    const handleCancel = () => {
        setIndex(c => c > 0 ? c - 1 : c);
    }

    let defaultCountry = '';

    if (countrySource.length > 0) {
        defaultCountry = countrySource.filter(i => i.code === 'NL').length > 0
        ? countrySource.filter(i => i.code === 'NL')[0].id
        : countrySource[0].id;
    }

    const defaultNewParticipant = getDefaultReservation({ countryId: defaultCountry });

    return (<div style={containerStyle}>
        <div style={{ padding: '5px' }}>
            {
                addons.map(
                    addon =>
                        addon.nr < total
                        && <Typography key={addon.productId} variant='subtitle2' color="primary">
                            {index < addon.nr ?
                                `(*) You have selected ${addon.nr} ${addon.productName} for ${total} participants. The person below will participate in the ${addon.productName}.`
                                : `(*) You have selected ${addon.nr} ${addon.productName} for ${total} participants. The person below will not participate in the ${addon.productName}.`
                            }
                        </Typography>

                )

            }
        </div>
        <EmptySpace height="10px" />
        <ParticipantDetailForm
            canPrevious={index > 0}
            isFinished={index === total - 1}
            action={dataArray[index] ? 'update' : 'new'}
            data={(dataArray[index] ?? defaultNewParticipant) as Reservation}
            countrySource={countrySource}
            existingEmails={dataArray.filter((_, idx) => idx !== index).map(i => i.email)}
            onSubmit={handleSubmit(dataArray[index] ? 'update' : 'new')}
            onCancel={handleCancel}
        />
    </div>);
}

const useCheckout = () => {
    const history = useHistory();
    const {
        state: { plan, products, nrOver18, nrUnder18, data: dataArray },
        addReservation,
        updateReservation,
    } = useReservationContext();

    const addons = products.filter(i => i.isAddon);

    const [countries, setCountries] = React.useState<ICountry[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        let isCancelled = false;
        setIsLoading(true);
        APIService.getCountries().then(data => {
            if (!isCancelled) {
                setCountries(data);
                setIsLoading(false);
            }
        });

        return () => {
            isCancelled = true;
        };
    }, []);

    const handleFinishing = () => {
        history.push('/payment');
    }

    return {
        history,
        plan, nrOver18, nrUnder18, dataArray,
        products,
        addons,
        countries,
        isLoading,
        addReservation, updateReservation,
        handleFinishing,
    }
}



const Mobile = ({ setTitle = () => { } }: Partial<WithLayoutMobileProps>) => {
    const { isLoading, addons, dataArray, nrOver18, nrUnder18, countries, handleFinishing, addReservation, updateReservation } = useCheckout();

    const { setIsLoading } = React.useContext(PageLoadingContext);
    React.useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading, setIsLoading]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<FillParticipants
        dataArray={dataArray}
        total={nrOver18 + nrUnder18}
        addons={addons}
        countrySource={countries}
        setTitle={setTitle}
        onFinished={handleFinishing}
        onAdded={addReservation}
        onUpdated={data => updateReservation(data.id, data)} />);
}

const Desktop = () => {
    const { isLoading, addons, dataArray, nrOver18, nrUnder18, countries, handleFinishing, addReservation, updateReservation } = useCheckout();

    const [title, setTitle] = React.useState('');

    const { setIsLoading } = React.useContext(PageLoadingContext);
    React.useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading, setIsLoading]);

    return (<Box marginTop="48px">
        <Typography variant='h6'>
            {title}
        </Typography>
        <EmptySpace height="10px" />
        <FillParticipants
            dataArray={dataArray}
            total={nrOver18 + nrUnder18}
            addons={addons}
            countrySource={countries}
            setTitle={setTitle}
            onFinished={handleFinishing}
            onAdded={addReservation}
            onUpdated={data => updateReservation(data.id, data)} />
    </Box>);
}

const Checkout = () => {
    const { isMobile } = useDevice();

    const {
        state: { plan, nrOver18, nrUnder18 },
    } = useReservationContext();

    return (<>
        {!plan ? <Redirect to="/" /> : null}
        {plan
            && (nrOver18 + nrUnder18 > 0)
            && (isMobile
                ? <LayoutMobile><Mobile /></LayoutMobile>
                : <LayoutDesktop>
                    <Desktop />
                </LayoutDesktop>)
        }
    </>);
}

export default Checkout;