import React from "react";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ICourseProduct } from "../../models/course";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            // fontSize: theme.typography.caption.fontSize,
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
        },
    }),
);


const SectionAddMoreProduct = ({
    data,
    onClick = () => { }
}: {
    data: ICourseProduct,
    onClick?: () => void
}) => {
    const classes = useStyles();

    return (<Card style={{
        margin: '8px',
        borderRadius: '10px'
    }}>
        <CardContent style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Typography component='div' variant='subtitle2' >
                        {`Vergroot jouw slaginskans voor het theorie examen en `}
                        <span style={{ fontWeight: 600 }}>boek nu de theorie cursus bij.</span>
                    </Typography>
                    <Typography component='div' variant='subtitle2' >
                        <span style={{ fontWeight: 600 }}>{`\u20AC${data.productPrice}`}&nbsp;</span>
                        <Typography component='span' variant='subtitle2' color='primary'>/ deelnemer</Typography>
                    </Typography>
                </div>
                <CardMedia
                    component="img"
                    image="/images/diamond.png"
                    style={{
                        width: 40,
                        height: 40,
                        padding: '4px'
                    }}
                />
            </div>

        </CardContent>
        <CardActions>
            <Button fullWidth size="small" className={classes.button} onClick={onClick} variant="contained" color="primary" >
                Ik doe mee aan de theorie cursus
            </Button>
        </CardActions>
    </Card>);
}

export default SectionAddMoreProduct;