import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ISearchResultData } from '../../models/search';
import { withWrapper } from '../layout/Wrapper';

const useStyles = makeStyles({
    root: {
        // height: '250px',
    },
    media: {
        height: '100px',
        backgroundSize: 'cover'
    },
    cardAction: {
        justifyContent: 'stretch',
    },
    button: {
        fontSize: '12px',
        borderRadius: 0,
        flex: 1
    },
});

export const SearchResultItem = ({ item, onSelect }: {
    item: ISearchResultData
    onSelect?: () => void,
}) => {
    const classes = useStyles();

    const { id, course: { name: courseName }, location: { name: locationName, city }, occurances, availableSlots } = item;

    return (<Card key={id} className={classes.root}>
        <CardActionArea>
            <CardMedia
                className={classes.media}
                image={`${item.course.imageURL}`}
            />
            <CardContent style={{
                padding: '5px'
            }}>
                <Typography gutterBottom component="div" variant="body2" style={{
                    fontWeight: 'bold'
                }}>
                    <div>{courseName}</div>
                    <div>{locationName} ({city})</div>
                </Typography>

                {occurances.sort((a, b) => a.start.localeCompare(b.start)).map((d) => {
                    const start = moment(d.start).locale('fr');
                    const end = moment(d.end).locale('fr');

                    return (<Typography key={`${id}-${d.start}`} component="div" variant="body2">
                        {`${start.format('dddd Do MMM')} - ${start.format('HH:mm')} uur -${end.format('HH:mm')} uur`}
                    </Typography>)
                })}

            </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardAction}>
            <Button
                variant="contained"
                size="small" color="primary"
                disabled={availableSlots <= 0}
                onClick={() => onSelect && onSelect()}
                className={classes.button}
            >
                {availableSlots > 1
                    ? `Nog ${availableSlots} plekken beschikbaar!`
                    : `Nog ${availableSlots} plek beschikbaar!`
                }
            </Button>
        </CardActions>
    </Card>);
}

export default withWrapper(SearchResultItem, { margin: '4px' });