import { ICountry } from '../models/country';
import { ICourse } from '../models/course';
import { ILocation } from '../models/location';
import { Reservation } from '../models/reservation';
import { IResponse } from '../models/response';
import { ISearchResult, ISearchResultData } from '../models/search';
import { IVoucherCodeResponse } from '../models/voucherCode';
import APIClient from './golfbooking';

export const delayIn = (ms: number) => new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const getCountries = async (): Promise<ICountry[]> => {
    const { data } = await APIClient.get<ICountry[]>(`/api/countries`);

    return data;
}

export const getCourses = async (): Promise<ICourse[]> => {
    const { data } = await APIClient.get<ICourse[]>(`/api/courses`, { params: { status: 'true', _sortBy: 'name_asc' }});

    return data;
}

export const getCourseById = async (id: string): Promise<ICourse> => {
    const { data } = await APIClient.get<ICourse>(`/api/courses/${id}`);

    return data;
}

export const getLocations = async (): Promise<ILocation[]> => {
    const { data } = await APIClient.get<ILocation[]>(`/api/locations`, { params: { status: 'true', _sortBy: 'name_asc' }});

    return data;
}

export const geLocationById = async (id: string): Promise<ILocation> => {
    const { data } = await APIClient.get<ILocation>(`/api/locations/${id}`);

    return data;
}

export const getPlanningById = async (id: string): Promise<ISearchResultData> => {
    const { data } = await APIClient.get<ISearchResultData>(`/api/plannings/${id}`);

    return data;
}

export const search = async (courseFilter: string, locationFilter: string, avaiableFilter: string, fromDateFilter: string | null, toDateFilter: string | null, nextPage: number = 0): Promise<ISearchResult> => {
    const sortBy = `_sortBy=startDate_asc`;
    const pageSize = `_pageSize=10`;
    const page = `_page=${nextPage}`;

    let query = `/api/booking/search?${pageSize}&${page}&${sortBy}&${avaiableFilter}&${courseFilter}&${locationFilter}`;

    if(fromDateFilter) {
        query += `&${fromDateFilter}`;
    }

    if(toDateFilter) {
        query += `&${toDateFilter}`;
    }

    const { data } = await APIClient.get<ISearchResult>(query);

    return data;
}

export const checkPromotionCode = async (code: string, courseId: string): Promise<IVoucherCodeResponse> => {
    try{
        const { data } = await APIClient.post<IResponse<IVoucherCodeResponse>>(`/api/booking/check-code`, null, {
            params: {
                code,
                course_id: courseId,
            }
        });
    
        return data.data;
    } catch(err: any) {
        if (err.serverError) {
            const data = err.serverError as IResponse<IVoucherCodeResponse>;
            let mess = `Opps onverwachte fout!`;

            if(data.errors) {
                const dataErrorMessage = data.errors[0];

                switch (dataErrorMessage) {
                    case 'invalid-code':
                        mess = 'Ingevoerde code onjuist'
                        break;
                    case 'not-support-course':
                        mess = 'Ingevoerde code onjuist'
                        break;
                    default:
                        mess = dataErrorMessage;
                        break;
                }
            }

            throw new Error(mess);
        } else {
            throw err;
        }
    }
}

export const registerBooking = async (reservations: Reservation[]) => {
    await APIClient.post(`/api/booking/register`, reservations);
}

export const generatePromoteCode = async (vendorId: string): Promise<IVoucherCodeResponse> => {
    try{
        const { data } = await APIClient.post<IResponse<IVoucherCodeResponse>>(`/api/booking/generate-vendor-promo-code`, null, {
            params: {
                vendor_id: vendorId,
            }
        });
    
        return data.data;
    } catch(err: any) {
        if (err.serverError) {
            const data = err.serverError as IResponse<IVoucherCodeResponse>;
            let mess = `Opps onverwachte fout!`;

            if(data.errors) {
                const dataErrorMessage = data.errors[0];

                switch (dataErrorMessage) {
                    case 'invalid-code':
                        mess = 'Ingevoerde code onjuist'
                        break;
                    case 'not-support-course':
                        mess = 'Ingevoerde code onjuist'
                        break;
                    default:
                        mess = dataErrorMessage;
                        break;
                }
            }

            throw new Error(mess);
        } else {
            throw err;
        }
    }
}