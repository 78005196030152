import { ICourseProduct } from "./course";
import { IVoucher } from "./voucherCode";

export interface IParticipantInfo {
    salutation: 'man' | 'woman',
    initial: string,
    firstName: string,
    lastName: string,
    middleName: string,
    birthDay: Date,
    countryId: string,
    countryName: string,
    addrStreet: string,
    addrHouseNumber: string,
    addrNumberAddition: string;
    addrZipCode: string,
    city: string,
    phone: string,
    email: string,
    iban: string,
    ibanName: string,
    refIban: string,
    refIbanName: string,
    paymentAmount: number,
}

export type Reservation = IParticipantInfo & {
    id: string,
    planningId: string,
    addonProducts: ICourseProduct[],
    promotionCodes: IVoucher[],
}

export const getDefaultReservation = (init: Partial<Reservation>): Partial<Reservation> => ({
    salutation: 'man',
    ...init,
});
