/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-danger */
import React from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDevice } from "../../hooks/device";
import CourseFullContentDialog from "../CourseFullContentDialog";
import GolfesContactDialog from "../GolfesContactDialog";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        linkButton: {
            fontSize: theme.typography.body1.fontSize,
            padding: theme.spacing(0),
            textDecorationLine: 'underline',
            fontWeight: 600
        },
    }),
);

const SectionSecondaryInfo = ({
    title = '',
    description = ''
}: {
    title?: string
    description?: string
}) => {
    const MAX_CHAR = 250;
    let showMore = false;
    let trimText = description;
    if (trimText.length > MAX_CHAR) {
        showMore = true;
        trimText = `${trimText.substring(0, MAX_CHAR)  } `;
    }

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [openContact, setOpenContact] = React.useState(false);

    const { isBrowser } = useDevice();

    return (<>
        <div style={{ padding: '8px' }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography gutterBottom component="div" variant="h6" style={{
                        fontWeight: 600,
                    }}>
                        Overige informatie
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="div">
                        <span dangerouslySetInnerHTML={{ __html: trimText }}></span>
                        {showMore &&
                            <Button onClick={() => setOpen(true)} className={classes.linkButton}>
                                Meer weergeven
                            </Button>

                        }
                    </Typography>
                    <Box mt={2} />
                    <Typography color='primary' onClick={() => setOpenContact(true)} style={{
                        fontWeight: 600,
                        textDecorationLine: 'underline',
                        cursor: 'pointer'
                    }}>Contact Golfles Nederland</Typography>
                </Grid>
            </Grid>
        </div>
        <CourseFullContentDialog fullScreen={!isBrowser} open={open} onClose={() => setOpen(false)} title={title} data={description} />
        <GolfesContactDialog fullScreen={!isBrowser} open={openContact} onClose={() => setOpenContact(false)} />
    </>);
}

export default SectionSecondaryInfo;