import { useDispatch, useSelector } from 'react-redux';
import { combineReducers, createStore, applyMiddleware, Reducer, } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";

import * as LocationModel from './locations/model';
import * as LocationAction from './locations/action';
import LocationReducer from './locations/reducer';

import * as CourseModel from './courses/model';
import * as CourseAction from './courses/action';
import CourseReducer from './courses/reducer';

import * as FilterModel from './filters/model';
import * as FilterAction from './filters/action';
import FilterReducer from './filters/reducer';

import * as NotificationModel from './notifications/model';
import * as NotificationAction from './notifications/action';
import NotificationReducer from './notifications/reducer';

import * as ReservationModel from './reservations/model';
import * as ReservationAction from './reservations/action';
import ReservationReducer from './reservations/reducer';
import { ISearchResultData } from '../models/search';
import { ICourse, ICourseProduct } from '../models/course';
import { ILocation } from '../models/location';
import { IVoucher } from '../models/voucherCode';
import { Reservation } from '../models/reservation';

// export type RootState = ReturnType<typeof rootReducer>;
export interface IRootState {
    location: LocationModel.IState
    course: CourseModel.IState,
    filter: FilterModel.IState,
    reservation: ReservationModel.IState,
    notification: NotificationModel.IState,
}

const rootReducer: Reducer<IRootState> = combineReducers({
    location: LocationReducer,
    course: CourseReducer,
    filter: FilterReducer,
    reservation: ReservationReducer,
    notification: NotificationReducer,
});



export const configureStore = (intialState?: IRootState) => {
    const store = createStore(rootReducer, intialState, composeWithDevTools(applyMiddleware(thunk)));
    return store;
}

export const useContext = <TSelected = unknown, TAction = unknown>(selector: (state: IRootState) => TSelected, action: TAction) => {
    const state = useSelector<IRootState, TSelected>(selector);
    const dispatch = useDispatch();

    return {
        state,
        dispatch,
        ...action,
    }
}

export const useLocationContext = () => {
    const state = useSelector<IRootState, LocationModel.IState>(selector => selector.location);
    const dispatch = useDispatch();

    return {
        state,
        fetch: () => dispatch(LocationAction.fetch()),
    }
}

export const useCourseContext = () => {
    const state = useSelector<IRootState, CourseModel.IState>(selector => selector.course);
    const dispatch = useDispatch();

    return {
        state,
        fetch: () => dispatch(CourseAction.fetch()),
    }
}

export const useFilterContext = () => {
    const state = useSelector<IRootState, FilterModel.IState>(selector => selector.filter);
    const dispatch = useDispatch();

    return {
        state,
        clear: () => dispatch(FilterAction.clear()),
        search: () => dispatch(FilterAction.search()),
        loadMore: () => dispatch(FilterAction.loadMore()),
        setFilterCourse: (items: ICourse[], onChanged = () => {}) => dispatch(FilterAction.setFilterCourse(items, onChanged)),
        setFilterLocation: (items: ILocation[], onChanged = () => {}) => dispatch(FilterAction.setFilterLocation(items, onChanged)),
        setNrParticipant: (over: number, under: number) => dispatch(FilterAction.setNrParticipant(over, under)),
        increaseNrOver: () => dispatch(FilterAction.increaseNrOver()),
        decreaseNrOver: () => dispatch(FilterAction.decreaseNrOver()),
        increaseNrUnder: () => dispatch(FilterAction.increaseNrUnder()),
        decreaseNrUnder: () => dispatch(FilterAction.decreaseNrUnder()),
        toggleShowOnlyAvaliableOption: () => dispatch(FilterAction.toggleShowOnlyAvaliableOption()),
        setExtraFilterDate: (date: Date) => dispatch(FilterAction.setExtraFilterDate(date)),
        setExtraVendorId: (vendorId: string) => dispatch(FilterAction.setExtraVendorId(vendorId)),
        removeExtraFilterDate: () => dispatch(FilterAction.removeExtraFilterDate()),
    }
}

export const useReservationContext = () => {
    const state = useSelector<IRootState, ReservationModel.IState>(selector => selector.reservation);
    const dispatch = useDispatch();

    return {
        state,
        clear: () => dispatch(ReservationAction.clear()),
        prepare: (plan: string | ISearchResultData, nrOver: number, nrUnder: number, vendorId?: string, callback?: () => void) => dispatch(ReservationAction.prepare(plan, nrOver, nrUnder, vendorId, callback)),
        changeNrParticipants: (over: number, under: number) => dispatch(ReservationAction.changeNrParticipants(over, under)),
        addAdditionProduct: (product: ICourseProduct) => dispatch(ReservationAction.addAdditionProduct(product)),
        updateAdditionProductNr: (productId: string, nr: number) => dispatch(ReservationAction.updateAdditionProductNr(productId, nr)),
        addPromotionCode: (code: IVoucher, minNrParticipant: number) => dispatch(ReservationAction.addPromotionCode(code, minNrParticipant)),
        removePromotionCode: (voucherId: string) => dispatch(ReservationAction.removePromotionCode(voucherId)),
        addReservation: (data: Reservation) => dispatch(ReservationAction.addReservation(data)),
        updateReservation: (id: string, data: Reservation) => dispatch(ReservationAction.updateReservation(id, data)),
        paymentForOne: (id: string, iban: string, ibanName: string, paymentAmount: number) => dispatch(ReservationAction.paymentForOne(id, iban, ibanName, paymentAmount)),
        paymentForAll: (dataArray: Reservation[]) => dispatch(ReservationAction.paymentForAll(dataArray)),
    }
}

export const useNotificationContext = () => {
    const state = useSelector<IRootState, NotificationModel.IState>(selector => selector.notification);
    const dispatch = useDispatch();

    return {
        state,
        showNotification: (item: Omit<NotificationModel.INotifyMessage, 'id'>) => dispatch(NotificationAction.showNotification(item)),
        removeNotification: (id: number) => dispatch(NotificationAction.removeNotification(id)),
        clearAll: () => dispatch(NotificationAction.clearAll()),
    }
}
