import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Box, Button, createStyles, makeStyles, Switch, TextField, Theme, Typography } from '@material-ui/core';
import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';
import { isMobile } from 'react-device-detect';

import { ICourseProduct } from '../../models/course';
import { Reservation } from '../../models/reservation';
import { IVoucher } from '../../models/voucherCode';
import { useReservationContext } from '../../store';
import LayoutMobile from '../layout/Layout.mobile';
import LayoutDesktop from '../layout/Layout.desktop';
import StickyFooter from '../StickyFooter';
import { PageLoadingContext } from '../layout/LoadingIndicator';
import { useLocationChange } from '../../hooks/routes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        inputRoot: {
            // fontSize: '16px'
        },
        labelRoot: {
            // fontSize: '16px',
            // color: "red",
            "&$labelFocused": {
                color: theme.palette.primary
            }
        },
        labelFocused: {},
        row: {
            display: 'flex',
            justifyContent: 'stretch'
        },
        button: {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '10px',
            margin: '5px',
            flex: 1
        },
        infoMessage: {
            color: theme.palette.primary.main
        }
    }),
);

const validateSchema = yup.object().shape({
    mode: yup.string(),
    all: yup.array()
        .when('mode', {
            is: (value: string) => value === 'all',
            then: yup.array().of(yup.object().shape({
                iban: yup.string().required('IBAN is verplicht').max(22, 'Maxlength is 22'),
                ibanName: yup.string().required('Tenaamstelling is verplicht'),
            }))
        }),
    one: yup.object()
        .when('mode', {
            is: (value: string) => value === 'one',
            then: yup.object().shape({
                iban: yup.string().required('IBAN is verplicht').max(22, 'Maxlength is 22'),
                ibanName: yup.string().required('Tenaamstelling is verplicht'),
            })
        }),
});

const PaymentItem = ({ data, amount, errorIBANMessage, erroIBANNameMessage, errorIBAN, errorIBANName, onIBANChange, onIBANNameChange, onIBANBlur, onIBANNameBlur }: {
    data: Reservation,
    amount: number,
    onIBANChange: (value: any) => void,
    onIBANNameChange: (value: any) => void,
    onIBANBlur: (value: any) => void,
    onIBANNameBlur: (value: any) => void
    errorIBANMessage?: string,
    errorIBAN: boolean,
    erroIBANNameMessage?: string,
    errorIBANName: boolean,
}) => {
    const classes = useStyles();

    return (<>
        <div className={classes.infoMessage}>{`Voor aanvang van de cursus zullen wij een bedrag van \u20AC${amount},-van uw rekening incasseren.`}</div>
        <div className={classes.row}>
            <TextField
                className={classes.textField}
                fullWidth
                margin="dense"
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                    classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                    }
                }}

                id={`${data.id}-iban`}
                name="iban"
                label="IBAN"
                value={data.iban || ''}
                onChange={onIBANChange}
                onBlur={onIBANBlur}
                error={errorIBAN}
                helperText={errorIBAN && errorIBANMessage}
            />
        </div>
        <div className={classes.row}>
            <TextField
                className={classes.textField}
                fullWidth
                margin="dense"
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                    classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                    }
                }}
                id={`${data.id}-tenaamstelling`}
                name="tenaamstelling"
                label="Tenaamstelling"
                value={data.ibanName || ''}
                onChange={onIBANNameChange}
                onBlur={onIBANNameBlur}
                error={errorIBANName}
                helperText={errorIBANName && erroIBANNameMessage}
            />
        </div>
    </>);
}

type FormDataType = {
    mode: 'one' | 'all',
    all: Reservation[],
    one: Reservation
};

const prepare = (nr: number, data: Reservation[], products: (ICourseProduct & { nr: number })[], codes: IVoucher[]): Reservation[] => {

    const productPrice = products.filter(x => !x.isAddon).reduce((acc, curr) => acc + (curr.productPrice * curr.nr), 0);
    const discountAmount = codes.reduce((acc, curr) => acc + curr.discountAmount, 0);

    let list = [...data];

    products.filter(x => x.isAddon && x.productPrice && x.nr > 0).forEach(({ nr: elNr, ...addon }) => {
        let count = 0;

        const calc = (c: number, array: Reservation[]) => array.map((item, index) => {
            if (index === c) {
                return {
                    ...item,
                    addonProducts: [addon],
                }
            }
            return item;
        });

        while (count < elNr) {
            list = calc(count, list);

            count += 1;
        }


    });

    const quotient = Math.floor((productPrice - discountAmount) / nr);
    const remainder = (productPrice - discountAmount) % nr;

    return list.map((p, index) => {
        const paymentAmount = (p.paymentAmount || 0) + ((p.addonProducts || []).reduce((a, c) => a + c.productPrice, 0)) + quotient + (index === 0 ? remainder : 0);
        return {
            ...p,
            paymentAmount: paymentAmount < 0 ? 0 : paymentAmount
        };
    });
}

const usePayment = () => {
    const history = useHistory();

    const {
        state: { loading: { submit: isSubmitting }, plan, data: dataArray, products, promotionCodes, nrOver18, nrUnder18 }, paymentForOne, paymentForAll
    } = useReservationContext();

    const totalAmount = products.reduce((a, c) => a + (c.productPrice * c.nr), 0) - promotionCodes.reduce((a, c) => a + c.discountAmount, 0);

    const initValue: FormDataType = {
        mode: 'one',
        all: prepare((nrOver18 + nrUnder18), dataArray, products, promotionCodes),
        one: ({ ...dataArray[0], paymentAmount: (totalAmount < 0 ? 0 : totalAmount) })
    }

    const handleSubmit = async (values: FormDataType) => {
        const { mode, all, one } = values;
        if (mode === 'one') {
            const { id, iban, ibanName, paymentAmount } = one;
            await paymentForOne(id, iban, ibanName, paymentAmount);
        }

        if (mode === 'all') {
            await paymentForAll(all);
        }

        history.push('/configuration');
    }

    const handleBack = () => {
        history.goBack();
    }

    const RequiredRedirect = () => (!plan || !dataArray || dataArray.length === 0) ? <Redirect to="/" /> : null;

    return {
        isSubmitting,
        initValue,
        totalAmount,
        handleSubmit,
        handleBack,
        RequiredRedirect
    }
}

const PaymentForm = ({
    initValue,
    onSumbmitted,
    onBackClicked,
    containerStyle,
}: {
    initValue: FormDataType,
    onSumbmitted: (values: FormDataType) => void,
    onBackClicked: () => void,
    containerStyle?: React.CSSProperties
}) => {
    const classes = useStyles();

    const formik = useFormik<FormDataType>({
        initialValues: initValue,
        validationSchema: validateSchema,
        onSubmit: onSumbmitted
    });

    return (<div style={containerStyle || {}}>
        <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            padding: '5px'
        }}>
            <form onSubmit={formik.handleSubmit}>
                <div style={{ display: 'flex', margin: '5px', alignItems: 'center' }}>
                    <Typography component='div' style={{ fontWeight: 'bold' }}>Mogen wij de kosten van een bankrekening incasseren?</Typography>
                    <Switch
                        color="primary"
                        disabled={formik.values.all.length === 1}
                        checked={formik.values.mode === 'one'}
                        name="mode"
                        onChange={(_, checked) => formik.setFieldValue('mode', checked ? 'one' : 'all')}
                    />
                </div>
                {formik.values.mode === 'all'
                    && formik.values.all
                    && formik.values.all.map((p, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>
                            <b>{p.firstName} {p.lastName}</b>
                            <PaymentItem
                                data={p}
                                amount={formik.values.all[index].paymentAmount}
                                onIBANChange={formik.handleChange(`all[${index}.iban]`)}
                                onIBANBlur={formik.handleBlur(`all[${index}.iban]`)}
                                onIBANNameChange={formik.handleChange(`all[${index}.ibanName]`)}
                                onIBANNameBlur={formik.handleBlur(`all[${index}.ibanName]`)}
                                errorIBAN={formik.errors.all && formik.errors.all.length > 0 && formik.errors.all[index] && Boolean((formik.errors.all[index] as FormikErrors<Reservation>).iban) || false}
                                errorIBANMessage={formik.errors.all && formik.errors.all.length > 0 && formik.errors.all[index] && (formik.errors.all[index] as FormikErrors<Reservation>).iban || ''}
                                errorIBANName={formik.errors.all && formik.errors.all.length > 0 && formik.errors.all[index] && Boolean((formik.errors.all[index] as FormikErrors<Reservation>).ibanName) || false}
                                erroIBANNameMessage={formik.errors.all && formik.errors.all.length > 0 && formik.errors.all[index] && (formik.errors.all[index] as FormikErrors<Reservation>).ibanName || ''} />
                        </div>
                    ))
                }
                {formik.values.mode === 'one'
                    && formik.values.one
                    && <PaymentItem
                        data={formik.values.one}
                        amount={formik.values.one.paymentAmount}
                        onIBANChange={formik.handleChange(`one.iban`)}
                        onIBANBlur={formik.handleBlur(`one.iban]`)}
                        onIBANNameChange={formik.handleChange(`one.ibanName`)}
                        onIBANNameBlur={formik.handleBlur(`one.ibanName`)}
                        errorIBAN={formik.errors.one && Boolean(formik.errors.one.iban) || false}
                        errorIBANMessage={formik.errors.one && formik.errors.one.iban}
                        errorIBANName={formik.errors.one && Boolean(formik.errors.one.ibanName) || false}
                        erroIBANNameMessage={formik.errors.one && formik.errors.one.ibanName} />
                }
            </form>

        </div>
        <StickyFooter>
            <div style={{
                display: 'flex',
                justifyContent: 'stretch',
                alignItems: 'stretch'
            }}>
                <Button
                    onClick={onBackClicked}
                    variant="outlined" color="primary"
                    className={classes.button}
                    style={{
                        textDecorationLine: 'underline'
                    }}
                >
                    Vorige
                </Button>
                <Button
                    disabled={!formik.isValid}
                    onClick={() => formik.handleSubmit()}
                    variant="contained" color="primary"
                    className={classes.button}
                >
                    Afronden
                </Button>
            </div>
        </StickyFooter>
    </div>);
}

const PaymentMain = () => {
    const { isSubmitting, initValue, handleSubmit, handleBack, RequiredRedirect } = usePayment();
    const { setIsLoading } = React.useContext(PageLoadingContext);
    React.useEffect(() => {
        if (isSubmitting) {
            setIsLoading(isSubmitting);
        }
    }, [isSubmitting, setIsLoading]);

    useLocationChange(() => {
        if (initValue.mode === 'one' && initValue.one.paymentAmount <= 0) {
            handleSubmit(initValue);
        }
    });

    return (<>
        <RequiredRedirect />
        <PaymentForm
            initValue={initValue}
            onSumbmitted={handleSubmit}
            onBackClicked={handleBack} />
    </>);
};


const Mobile = () => (
    <LayoutMobile>
        <PaymentMain />
    </LayoutMobile>
)

const Desktop = () => (<>
    <LayoutDesktop>
        <Box marginTop="48px">
            <PaymentMain />
        </Box>
    </LayoutDesktop>
</>)

const Payment = () => (<>
    {isMobile
        ? <Mobile />
        : <Desktop />
    }
</>)

export default Payment;