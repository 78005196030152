import React from 'react';
import { Box, Container } from '@material-ui/core';

import { LayoutProps } from './Layout.props';
import { withFullScreenLoading } from './LoadingIndicator';
import AppHeader from './AppHeader';

const Layout = ({ containerStyle = {}, maxWidth = 'lg', header = { showCalendar: false } , children, ...rest }: LayoutProps) => {
    const childrenWithProps = React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...rest });
        }
        return child;
    });
    return (<div className="page">

        <AppHeader maxWidth={maxWidth} showCalendar={header.showCalendar} />
        <Box />
        <main style={{
            display: 'block'
        }}>
            <Container maxWidth={maxWidth}>
                <div style={containerStyle}>
                    {childrenWithProps}
                </div>
            </Container>


        </main>
    </div>)
}

export default withFullScreenLoading(Layout);