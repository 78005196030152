
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

import { ICourseCondition } from "../../models/course";
import CourseFullContentDialog from "../CourseFullContentDialog";
import { useDevice } from "../../hooks/device";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        linkButton: {
            padding: theme.spacing(0),
            textDecorationLine: 'underline',
            fontWeight: 600,
            textTransform: 'lowercase'
        },
    }),
);

const SectionConditionItem = ({
    title = '',
    subtitle = '',
    icon,
    price,
    moreButtonTitle = 'Meer informatie'
}: {
    title?: string,
    subtitle?: string,
    icon?: JSX.Element,
    price?: number,
    moreButtonTitle?: string,
}) => {
    const [open, setOpen] = React.useState(false);
    const MAX_CHAR = 100;
    let showMore = false;
    let trimText = subtitle;
    if (trimText.length > MAX_CHAR) {
        showMore = true;
        trimText = `${trimText.substring(0, MAX_CHAR)  } `;
    }

    const { isBrowser } = useDevice();

    const classes = useStyles();

    return (<>
        <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '4px'
        }}>
            <div style={{ alignSelf: 'stretch', marginRight: '16px'  }}>
                {icon}
            </div>
            <div style={{
                flex: 1,
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Typography component="div" variant='subtitle1' style={{ fontWeight: 600 }}>
                        {title}
                    </Typography>
                    {price &&
                        <Typography color='secondary'>
                            {`\u20AC${price},-`}
                        </Typography>
                    }
                </div>
                <Typography component="div" variant='subtitle2'>
                    {trimText}
                    {showMore && moreButtonTitle && (<>
                        {` `}
                        <Button className={classes.linkButton} onClick={() => setOpen(true)}>
                            {moreButtonTitle}
                        </Button>

                    </>)}

                </Typography>
            </div>
        </div>

        <CourseFullContentDialog fullScreen={!isBrowser} open={open} onClose={() => setOpen(false)} title={title} data={subtitle} />
    </>);
}


const SectionConditions = ({
    data = []
}: {
    data?: ICourseCondition[]
}) => (<>
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '8px'
    }}>
        {data.sort((a,b) => a.conditionOrder - b.conditionOrder).map(item => <SectionConditionItem key={item.conditionId}
            icon={<img alt="condition icon" style={{ width: '30px', height: '30px' }} src={`${item.conditionIconURL}`} />}
            title={item.conditionName}
            subtitle={item.conditionDescription}
            price={item.conditionPrice}
        />)}
    </div>
</>);

export default SectionConditions;