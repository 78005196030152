import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobileOnly
  } from "react-device-detect";

export const useDevice = () => ({
        BrowserView,
        MobileView,
        isBrowser,
        isMobile: isMobileOnly
    })

export default useDevice;
