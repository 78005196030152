import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { LayoutProps } from './Layout.props';
import { withFullScreenLoading } from './LoadingIndicator';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'grid',
            gridTemplateAreas: "'header' 'main' 'footer'",
            gridTemplateRows: '40px 1fr 0px',
            gridTemplateColumns: '1fr',
            height: '100%'
        },
        header: {
            gridArea: 'header',
            // border: '2px white solid',
            display: 'flex',
            padding: theme.spacing(1),
            alignItems: 'flex-end',
            // color: 'white'

        },
        main: {
            gridArea: 'main',
            // border: '2px white solid',
            backgroundColor: 'white',
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            paddingTop: '30px'
        },
        footer: {
            gridArea: 'footer',
            // border: '2px white solid'
        },
    }),
);

const Layout = ({ children, ...rest }: LayoutProps) => {
    const styles = useStyles();
    const [title, setTitle] = React.useState<string>();

    const callSetTitle = React.useCallback((titleValue: string) => {
        setTitle(titleValue);
    }, [setTitle]);

    const childrenWithProps = React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...rest, setTitle: callSetTitle });
        }
        return child;
    });

    return (<div className={styles.root}>
        <div className={styles.main}>
            <Typography className={styles.header} component="div" variant="h6">
                {title}
            </Typography>
            {childrenWithProps}
        </div>

        <div className={styles.footer}>
            {/** empty placeholder  */}
        </div>
    </div>)
}

export default withFullScreenLoading(Layout);

export interface WithLayoutMobileProps {
    setTitle?: (title: string) => void,
}
