import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography, IconButton, Grid, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import StickyHeader from '../StickyHeader';
import StickyFooter from '../StickyFooter';
import { useReservationContext } from '../../store';
import { ICourseProduct } from '../../models/course';
import { withWizard } from '../wizard/withWizard';
import AlertDialog from '../AlertDialog';
import SectionAddMoreProduct from './SectionAddMoreProduct';
import SectionProducts from './SectionProducts';
import SectionCourseInfo from './SectionCourseInfo';
import SectionPromotionCode from './SectionPromotionCode';
import EmptySpace from '../layout/EmptySpace';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        button: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            margin: theme.spacing(1),
        },
    }),
);

const PriceOverview = () => {

    const classes = useStyles();

    const history = useHistory();

    const {
        state: { plan, nrOver18, nrUnder18, products, promotionCodes, minNrParticipant },
        changeNrParticipants, addAdditionProduct, updateAdditionProductNr,
        addPromotionCode, removePromotionCode
    } = useReservationContext();

    const handleSubmit = () => {
        if (plan) {
            if (nrOver18 + nrUnder18 > plan.availableSlots) {
                setAlert({ open: true, title: 'Error', content: `Only ${plan.availableSlots} spots are available for this course please adjust nr. of participants accordingly to book` });
                return;
            }
            history.push('/checkout');
        }
    }

    const handleBack = async () => {
        history.goBack();
    }

    const handleParticipantsChanged = (over18: number, under18: number) => {
        changeNrParticipants(over18, under18);
    }

    const handleAddMoreProduct = async (product: ICourseProduct) => {
        addAdditionProduct(product);
    }
    const handleUpdateNrProduct = (productId: string, participants: number) => {
        updateAdditionProductNr(productId, participants);
    };

    const handleAddRequiredNr = () => {
        const diff = minNrParticipant - nrOver18 - nrUnder18;

        if (diff > 0) {
            changeNrParticipants(nrOver18 + diff, nrUnder18);
        }

    }

    const [alert, setAlert] = React.useState<Partial<{ open: boolean, title: string, content: string }>>({ open: false });

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<>
        <AlertDialog open={alert.open || false} title={alert.title || ''} content={alert.content || ''} onClose={() => setAlert({ open: false })} />

        {plan
            && <>
                <StickyHeader
                    start={<IconButton
                        onClick={() => handleBack()}
                        style={{
                            padding: '5px'
                        }}
                    >
                        <CloseIcon style={{
                            fontSize: '20px',
                        }} />
                    </IconButton>}
                />
                <Grid container spacing={1} style={{ padding: '8px' }}>
                    <Grid item xs={12}>
                        <Typography gutterBottom component="div" variant="h4" style={{
                            fontWeight: 500,
                            margin: '4px',
                            padding: '4px'
                        }}>
                            Je cursus
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SectionCourseInfo
                            showDialogFullScreen
                            startDate={plan.startDate}
                            location={plan.location.name}
                            over={nrOver18}
                            under={nrUnder18}
                            onParticipantsChanged={handleParticipantsChanged} />
                    </Grid>
                    <Grid item xs={12}>
                        <SectionPromotionCode
                            data={promotionCodes}
                            selectedCourse={plan.course}
                            onAddCode={(code, minNr) => addPromotionCode(code, minNr)}
                            onRemoveCode={codeId => removePromotionCode(codeId)} />
                    </Grid>
                    <Grid item xs={12}>
                        <SectionProducts
                            data={products}
                            promotions={promotionCodes}
                            maxNr={nrOver18 + nrUnder18}
                            onUpdateNr={handleUpdateNrProduct} />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            plan.course.products
                                .filter(x => x.isAddon && products.filter(p => p.productId === x.productId).length === 0)
                                .map(prod => <SectionAddMoreProduct key={prod.productId} data={prod} onClick={() => handleAddMoreProduct(prod)} />)
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <EmptySpace height="40px" />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box paddingLeft={2} paddingRight={2}>
                        {minNrParticipant > 1 && minNrParticipant > (nrOver18 + nrUnder18)
                            ? <>
                                <Typography component='div' color='error' variant='subtitle2' >
                                    {`(*) ${promotionCodes[0].code} vereist minimaal ${minNrParticipant} deelnemers. `}
                                    <Typography component='span' color='primary' onClick={handleAddRequiredNr} style={{
                                        fontWeight: 600,
                                        textDecorationLine: 'underline',
                                        cursor: 'pointer'
                                    }}>Klik hier om toe te voegen</Typography>
                                </Typography>
                            </>

                            : <></>
                        }
                    </Box>
                </Grid>

                <StickyFooter>
                    <Button
                        size='large'
                        variant="contained" color="primary"
                        onClick={handleSubmit}
                        className={classes.button}
                        disabled={minNrParticipant > 1 && minNrParticipant > (nrOver18 + nrUnder18)}
                    >
                        Reserveren
                    </Button>
                </StickyFooter>
            </>
        }
    </>);
}

export default PriceOverview;

export const PriceOverviewStep = withWizard(PriceOverview);