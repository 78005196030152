import React from 'react';

type Props = {
    start?: JSX.Element,
    component?: JSX.Element,
    end?: JSX.Element,
    noSticky?: boolean,
}

const StickyHeader = ({
    start,
    component,
    end,
    noSticky = false
}: Props) => {

    const defaultRootStyle = {
        display: 'flex',
        padding: '4px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
    }

    const stickyStyle = {
        position: 'sticky',
        top: 0,
        zIndex: 99,
    }

    return (<div style={noSticky ? defaultRootStyle : {...defaultRootStyle, ...stickyStyle}} >
        {start && start}
        <div
            style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center'
            }}
        >
            {component}
        </div>
        {end && end}
    </div>)
}

export default StickyHeader;