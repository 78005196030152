import React from 'react';
import { Search } from '@material-ui/icons';
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

import { useFilterContext } from '../../store';
import SearchBarSmall from './SearchBarSmall';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            border: '1px solid #DDDDDD',
            borderRadius: theme.spacing(6),
            color: '#222222',
            display: 'flex',
            position: 'relative',
            width: '100%',
            height: '66px',
            boxShadow: '0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%)',
            // --big-search-form-background
            backgroundColor: '#FFFFFF'
        },
        insideContainer: {
            display: 'flex',
            flex: '1',
            height: '100%',
            minWidth: '0px',
            pointerEvents: 'auto',
        },
        itemContainer: {
            minWidth: '0px',
        },
        item: {
            position: 'relative',
            alignItems: 'center',
            display: 'flex',
            flex: '1 0 0%',
            margin: '-1px',
            minWidth: '0px',
        },
        itemLabel: {
            cursor: 'pointer',
            display: 'block',
            backgroundClip: 'padding-box',
            border: '1px solid transparent',
            borderRadius: '32px',
            flex: '1 0 0%',
            minWidth: '0px',
            padding: '14px 32px',
            "&::hover": {
                backgroundColor: 'rebeccapurple'
            }
        },
        itemLabelInside: {
            position: 'relative',
            zIndex: 1,
        },
        itemLabelText: {
            fontWeight: 800,
            letterSpacing: '0.04em',
            fontSize: '12px',
            lineHeight: '16px',
            paddingBottom: '2px',
        },
        itemSubLabelText: {
            display: 'block',
            border: '0px',
            margin: '0px',
            padding: '0px',
            width: '100%',
            background: 'none',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: 600,
            color: '#222222',
            textOverflow: 'ellipsis',
        },
        seperateLine: {
            alignSelf: 'center',
            borderRight: '1px solid #DDDDDD',
            flex: '0 0 0px',
            height: '32px',
        },
        lastItemContainer: {
            alignItems: 'center',
            display: 'flex',
            margin: '-1px',
            minWidth: '0px',
            position: 'relative',
            flex: '0.95 0 auto'
        },
        lastItemInsideContainer: {
            padding: '14px 24px',
            overflow: 'hidden',
            position: 'relative',
            whiteSpace: 'nowrap',
            width: '100%',
            zIndex: 1,
        },
        backdrop: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            inset: 0,
            position: 'fixed',
            zIndex: 0,
        }
    }),
);


const SearchBarExpanse = ({
    onSearchClick = () => {},
    outerClick = () => {},
    onLocationClick = () => {},
    onCourseClick = () => {},
    onGuestClick = () => {},
}:{
    onSearchClick?: () => void,
    outerClick?: () => void,
    onLocationClick?: () => void,
    onCourseClick?: () => void,
    onGuestClick?: () => void
}) => {
    
    const classes = useStyles();

    const { state: { filter, } } = useFilterContext();

    const locationFilterString = filter
        && filter.locations
        && filter.locations.length > 0
        && (filter.locations.length === 1
            ? filter.locations[0].name
            : `${filter.locations[0].name} en ${filter.locations.length - 1} andere`);

    const courseFilterString = filter
        && filter.courses
        && filter.courses.length > 0
        && (filter.courses.length === 1
            ? filter.courses[0].name
            : `${filter.courses[0].name} en ${filter.courses.length - 1} andere`);

    const nrParticipant = filter.over + filter.under;

    return (<div>
        <div role="presentation" className={classes.backdrop} onClick={() => outerClick()}>{` `}</div>
        <div className={classes.container}>
            <>
                <div className={classes.insideContainer}>
                    <div role="presentation"  className={classes.itemContainer} onClick={onLocationClick}>
                        <div className={classes.item}>
                            <label className={classes.itemLabel}>
                                <div className={classes.itemLabelInside}>
                                    <Typography variant='subtitle2'>Locaties</Typography>
                                    <Typography variant='subtitle2' style={{ fontStyle: 'italic' }} color='textSecondary'>
                                        {filter.locations.length === 0
                                            ? `Kies locatie(s)`
                                            : locationFilterString
                                        }
                                    </Typography>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={classes.seperateLine}>{` `}</div>
                    <div role="presentation"  className={classes.itemContainer} onClick={onCourseClick}>
                        <div className={classes.item}>
                            <label className={classes.itemLabel}>
                                <div className={classes.itemLabelInside}>
                                    <Typography variant='subtitle2'>Cursus</Typography>
                                    <Typography variant='subtitle2' style={{ fontStyle: 'italic' }} color='textSecondary'>
                                        {filter.courses.length === 0
                                            ? `Kies cursus(sen)`
                                            : courseFilterString
                                        }
                                    </Typography>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={classes.seperateLine}>{` `}</div>
                    <div role="presentation" className={classes.itemContainer} onClick={onGuestClick}>
                        <div className={classes.item}>
                            <label className={classes.itemLabel}>
                                <div className={classes.itemLabelInside}>
                                    <Typography variant='subtitle2'>Deelnemers</Typography>
                                    <Typography variant='subtitle2' style={{ fontStyle: 'italic' }} color='textSecondary'>
                                        {`${nrParticipant} ${nrParticipant > 1 ? 'cursisten' : 'cursist'}`}
                                    </Typography>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '55px',
                        height: '100%'
                    }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                borderRadius: '50%',
                                minWidth: '50px',
                                minHeight: '50px',
                                padding: 0,
                                zIndex: 1
                            }}
                            onClick={onSearchClick}
                        >
                            <Search />
                        </Button>
                    </div>
                </div>
            </>
        </div>
    </div>);
}

const SearchBar = ({
    onHomeClick = () => {},
    onLocationClick = () => {},
    onCourseClick = () => {},
    onGuestClick = () => {},
}: {
    onHomeClick?: () => void,
    onLocationClick?: () => void,
    onCourseClick?: () => void,
    onGuestClick?: () => void
}) => {
    const [expand, setExpand] = React.useState(false);

    return <>
        {expand 
            ? <SearchBarExpanse 
                onLocationClick={onLocationClick}
                onCourseClick={onCourseClick}
                onGuestClick={onGuestClick}
                onSearchClick={() => {
                    setExpand(false);
                    onHomeClick();
                }}
                outerClick={() => setExpand(false)} />
            : <SearchBarSmall onClick={() => setExpand(true)} />
        }
    </>;
}

export default SearchBar;