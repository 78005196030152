import { Reducer } from 'redux';
import * as MODEL from './model';

const reducer: Reducer<MODEL.IState> = (state: MODEL.IState = MODEL.initialState, action: MODEL.Actions) => {
    switch(action.type) {
        case MODEL.ActionType.GET_LIST_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                }
            }
        case MODEL.ActionType.GET_LIST_COMPLETED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                list: [...action.payload.data]
            }
        default:
            return state;
    }
}

export default reducer;
