import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';

import { useDevice } from '../../hooks/device';
import { useReservationContext } from '../../store';
import LayoutDesktop from '../layout/Layout.desktop';
import BookingWizard from '../wizard/BookingWizard';
import { CourseOverviewStep } from './CourseOverview';
import { Desktop as CourseOverViewCourseInfo } from './CourseOverViewCourseInfo';
import { PriceOverviewStep } from './PriceOverview';
import SectionProgram from './SectionProgram';
import SectionConditions from './SectionConditions';
import SectionSecondaryInfo from './SectionSecondaryInfo';
import SectionLocationMap from './SectionLocationMap';
import LayoutMobile from '../layout/Layout.mobile';
import AlertDialog from '../AlertDialog';
import SectionAddMoreProduct from './SectionAddMoreProduct';
import SectionProducts from './SectionProducts';
import SectionCourseInfo from './SectionCourseInfo';
import SectionPromotionCode from './SectionPromotionCode';

const Mobile = () => (<LayoutMobile>
    <BookingWizard>
        <CourseOverviewStep step={1} />
        <PriceOverviewStep step={2} />
    </BookingWizard>
</LayoutMobile>);


const Desktop = () => {
    const history = useHistory();

    const { state: { plan: selectedCourse, products, promotionCodes, nrOver18, nrUnder18, minNrParticipant }
        , addAdditionProduct, updateAdditionProductNr, changeNrParticipants
        , addPromotionCode, removePromotionCode
    } = useReservationContext();

    const [alert, setAlert] = React.useState<Partial<{ open: boolean, title: string, content: string }>>({ open: false });

    const handleSubmit = () => {
        if (selectedCourse) {
            if (nrOver18 + nrUnder18 > selectedCourse.availableSlots) {
                setAlert({ open: true, title: 'Error', content: `Only ${selectedCourse.availableSlots} spots are available for this course please adjust nr. of participants accordingly to book` });
                return;
            }
            history.push('/checkout');
        }
    }

    const handleAddRequiredNr = () => {
        const diff = minNrParticipant - nrOver18 - nrUnder18;

        if (diff > 0) {
            changeNrParticipants(nrOver18 + diff , nrUnder18);
        }
        
    }

    return (<>
        <AlertDialog open={alert.open || false} title={alert.title || ''} content={alert.content || ''} onClose={() => setAlert({ open: false })} />
        {selectedCourse
            && <LayoutDesktop>
                <Box height="200px" margin={1}>
                    <CourseOverViewCourseInfo item={selectedCourse} />
                </Box>
                <Divider light />
                <Grid container spacing={4}>
                    <Grid item md={8} sm={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <SectionProgram
                                    caption={selectedCourse.course.programHeader}
                                    detail={selectedCourse.course.program}
                                    logo={`${selectedCourse.location.logoURL}`} />
                                <Divider light />
                            </Grid>
                            <Grid item xs={12}>
                                <SectionConditions data={selectedCourse.course.conditions} />
                                <Divider light />
                            </Grid>
                            <Grid item xs={12}>
                                <SectionSecondaryInfo title={selectedCourse?.location.name} description={selectedCourse.location.description} />
                                <Divider light />
                            </Grid>
                            <Grid item xs={12}>
                                <SectionLocationMap lat={selectedCourse.location.lat} lng={selectedCourse.location.lng} zoom={selectedCourse.location.zoom} address={selectedCourse.location.name} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <div style={{
                            position: 'sticky',
                            top: '80px',
                            zIndex: 1,
                            display: 'inline-block',
                            width: '100%',
                            paddingRight: '1px'
                        }}>
                            <div style={{ paddingBottom: '48px' }}>
                                <div style={{ marginTop: '48px' }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <SectionCourseInfo
                                                showDialogFullScreen={false}
                                                startDate={selectedCourse?.startDate}
                                                location={selectedCourse?.location?.name}
                                                over={nrOver18}
                                                under={nrUnder18}
                                                onParticipantsChanged={(over18: number, under18: number) => changeNrParticipants(over18, under18)} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SectionPromotionCode
                                                data={promotionCodes}
                                                selectedCourse={selectedCourse.course}
                                                onAddCode={(code, minNr) => addPromotionCode(code, minNr)}
                                                onRemoveCode={codeId => removePromotionCode(codeId)} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SectionProducts
                                                data={products}
                                                promotions={promotionCodes}
                                                maxNr={nrOver18 + nrUnder18}
                                                onUpdateNr={(productId: string, participants: number) => updateAdditionProductNr(productId, participants)} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                selectedCourse && selectedCourse.course.products
                                                    .filter(x => x.isAddon && products.filter(p => p.productId === x.productId).length === 0)
                                                    .map(prod => <SectionAddMoreProduct key={prod.productId} data={prod} onClick={() => addAdditionProduct(prod)} />)
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                                                <Button
                                                    fullWidth
                                                    size='large'
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                    disabled={minNrParticipant > 1 && minNrParticipant > (nrOver18 + nrUnder18)}
                                                >
                                                    Reserveren
                                                </Button>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box paddingLeft={2} paddingRight={2}>
                                                {minNrParticipant > 1 && minNrParticipant > (nrOver18 + nrUnder18)
                                                    ? <>
                                                        <Typography component='div' color='error' variant='subtitle2' >
                                                            {`(*) ${promotionCodes[0].code} vereist minimaal ${minNrParticipant} deelnemers. `}
                                                            <Typography component='span' color='primary' onClick={handleAddRequiredNr} style={{
                                                                fontWeight: 600,
                                                                textDecorationLine: 'underline',
                                                                cursor: 'pointer'
                                                            }}>Klik hier om toe te voegen</Typography>
                                                        </Typography>
                                                    </>

                                                    : <></>
                                                }
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </div>

                            </div>

                        </div>
                    </Grid>
                </Grid>
            </LayoutDesktop>
        }
    </>);
}

const CheckoutSummary = () => {
    const { isMobile } = useDevice();

    const { state: { plan: selectedCourse } } = useReservationContext();

    return (<>
        {!selectedCourse ? <Redirect to="/" /> : null}
        {selectedCourse && isMobile ? <Mobile /> : <Desktop />}
    </>);
}

export default CheckoutSummary;