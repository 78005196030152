import { ActionMap } from "../utils"

export enum ActionType {
    SHOW_MESSAGE = 'NOTIFICATION/SHOW_MESSAGE',
    REMOVE_MESSAGE = 'NOTIFICATION/REMOVE_MESSAGE',
    CLEAR_ALL = 'NOTIFICATION/CLEAR_ALL',
}

export type Payload = {
    [ActionType.SHOW_MESSAGE]: INotifyMessage;
    [ActionType.REMOVE_MESSAGE]: number;
    [ActionType.CLEAR_ALL]: undefined;
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export interface INotifyMessage {
    id: number,
    message: string,
    type: 'info' | 'error',
    showing: boolean,
}

export interface IState {
    count: number,
    list: INotifyMessage[]
}

export const initialState: IState = {
    count: 0,
    list: [],
}