/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";


export const SlideTransition = React.forwardRef((
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

export default SlideTransition;