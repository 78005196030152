import React from 'react';


interface Props {
    init?: number,
    children?: React.ReactElement[] | React.ReactElement,
}


type ContextState = {
    currentStep: number,
    goNext: () => void,
    goBack: () => void,
};


const BookingWizardContext = React.createContext<Partial<ContextState>>({});

const BookingWizard = ({ init = 1, children, ...rest }: Props) => {


    const [currentStep, setCurrentStep] = React.useState<number>(init);

    const goNext = () => setCurrentStep(c => (c + 1));

    const goBack = () => setCurrentStep(c => c > 1 ? (c - 1) : c);

    const state = { currentStep };

    const action = {
        goNext, goBack
    };

    const childrenWithProps = React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {...rest});
        }
        return child;
    });

    return (
        <BookingWizardContext.Provider value={{ ...state, ...action }}>
            { childrenWithProps }
        </BookingWizardContext.Provider>

    );
}

export { BookingWizardContext }
export default BookingWizard;