import { Reducer } from 'redux';
import * as MODEL from './model';

const reducer: Reducer<MODEL.IState> = (state: MODEL.IState = MODEL.initialState, action: MODEL.Actions) => {
    switch (action.type) {
        case MODEL.ActionType.GET_PLAN_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                }
            }
        case MODEL.ActionType.GET_PLAN_COMPLETE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                },
                plan: action.payload,
            }
        case MODEL.ActionType.SET_NR_OVER:
            return {
                ...state,
                nrOver18: action.payload,
                products: state.products.map(item => ({ ...item, nr: action.payload + state.nrUnder18 })),
            }
        case MODEL.ActionType.SET_NR_UNDER:
            return {
                ...state,
                nrUnder18: action.payload,
                products: state.products.map(item => ({ ...item, nr: state.nrOver18 + action.payload }))
            }
        case MODEL.ActionType.SET_PRODUCTS:
            return {
                ...state,
                products: [...action.payload],
            }
        case MODEL.ActionType.ADD_ADDITION_PRODUCT:
            return {
                ...state,
                products: [...state.products, { ...action.payload, nr: state.nrOver18 + state.nrUnder18 }]
            }
        case MODEL.ActionType.UPDATE_ADDITION_PRODUCT_NR:
            return {
                ...state,
                products: state.products.map(p => p.productId === action.payload.productId ? ({ ...p, nr: action.payload.nr }) : p)
            }
        case MODEL.ActionType.REMOVE_ADDITION_PRODUCT:
            return {
                ...state,
                products: state.products.filter(p => p.productId !== action.payload)
            }
        case MODEL.ActionType.ADD_PROMOTION_CODE:
            return {
                ...state,
                promotionCodes: [...state.promotionCodes, action.payload.voucher],
                minNrParticipant: action.payload.minNrParticipant,
            }
        case MODEL.ActionType.REMOVE_PROMOTION_CODE:
            return {
                ...state,
                promotionCodes: state.promotionCodes.filter(item => item.id !== action.payload),
                minNrParticipant: 0
            }

        // case MODEL.ActionType.CLEAR_ALL:
        //     return {
        //         ...state,
        //         plan: undefined,
        //         nrOver18: 0,
        //         nrUnder18: 0,
        //         products: [],
        //         promotionCodes: [],
        //         data: []
        //     }

        case MODEL.ActionType.CLEAR_ALL:
            return {
                ...state,
                plan: action.payload.plan,
                nrOver18: action.payload.nrOver18,
                nrUnder18: action.payload.nrUnder18,
                products: [...action.payload.products],
                promotionCodes: [...action.payload.promotionCodes],
                data: [...action.payload.data],
                minNrParticipant: action.payload.minNrParticipant
            }
        case MODEL.ActionType.ADD_RESERVATION:
            return {
                ...state,
                data: [...state.data, action.payload],
            }
        case MODEL.ActionType.UPDATE_RESERVATION:
            return {
                ...state,
                data: state.data.map(item => item.id === action.payload.id ? ({ ...item, ...action.payload.data }) : item)
            }

        case MODEL.ActionType.SUBMIT_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    submit: true,
                }
            }
        case MODEL.ActionType.SUBMIT_COMPLETE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    submit: false,
                }
            }
        default:
            return state;
    }
}

export default reducer;
