/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import env from '../enviroment';

async function handleError(error: any) {

    const capturedError = { ...error };

    if (capturedError.response) {
        const { data } = error.response as AxiosResponse<any>;
        capturedError.serverError = data;
    }

    throw capturedError;
}

function logRequest(config: AxiosRequestConfig) {
    console.log('Request: ', config.baseURL, config.url, config.method, config.params, config.data);
    return config;
}

function logResponseValue(value: AxiosResponse) {
    console.log('Response: ', value?.config.url, value?.status, value?.data);
    return value;
}

function logResponseError(error: any) {
    console.log('Response error: ', error, error.response?.status);
    return Promise.reject(error);
}

const appInstance = axios.create({});

// appInstance.interceptors.request.use(logRequest);
// appInstance.interceptors.response.use(logResponseValue, logResponseError);
appInstance.interceptors.response.use(
    value => value,
    handleError
);

export default appInstance;