import React from 'react';
import {
    Button, Divider, Typography, IconButton, Dialog, DialogTitle, DialogContent, Card, CardContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

import { ParticipantSelection } from '../search/InputParticipants';


const SectionCourseInfo = ({
    startDate = new Date(),
    location,
    onParticipantsChanged = () => { },
    over,
    under,
    showDialogFullScreen,
}: {
    startDate?: Date,
    location?: string,
    onParticipantsChanged?: (over18: number, under18: number) => void,
    over: number,
    under: number,
    showDialogFullScreen?: boolean,
}) => {
    const [state, setState] = React.useState<{
        open: boolean,
        over: number,
        under: number,
    }>({
        open: false,
        over,
        under,
    });

    const handleOpen = () => {
        setState(c => ({ ...c, open: true, over, under }))
    }

    const handleClose = () => {
        setState(c => ({ ...c, open: false, }));
    }

    return (<>
        <Dialog
            fullScreen={showDialogFullScreen}
            open={state.open}
            disableBackdropClick
        >
            <DialogTitle style={{ padding: '4px' }}>
                <IconButton onClick={handleClose} style={{ padding: '4px' }}>
                    <CloseIcon style={{ fontSize: '20px' }} />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <div style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    paddingTop: '8px'
                }}>
                    <ParticipantSelection
                        over={state.over}
                        under={state.under}
                        onChange={(newOverNr, newUnderNr) => setState(c => ({ ...c, over: newOverNr, under: newUnderNr, }))}
                        button={<Button variant="contained" color="primary" fullWidth onClick={() => { onParticipantsChanged(state.over, state.under); handleClose(); }}>Bijwerken</Button>}
                    />
                </div>

            </DialogContent>
        </Dialog>

        <Card style={{
            margin: '5px',
            borderRadius: '10px'
        }}>
            <CardContent style={{ padding: '4px'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'stretch'
                }}>
                    <div style={{
                        flex: 1,
                        padding: '5px',
                    }}>
                        <Typography
                            component='div'
                            style={{ fontWeight: 600 }}
                        >
                            Startdatum
                        </Typography>
                        <Typography component="div" variant='body1' >
                            {moment(startDate).format(`DD-MM-YYYY`)}
                        </Typography>
                    </div>
                    <Divider orientation="vertical" light flexItem />
                    <div role="presentation" onClick={handleOpen} style={{
                        flex: 1,
                        padding: '5px',
                        cursor: 'pointer'
                    }}>
                        <Typography
                            component='div'
                            style={{ fontWeight: 600 }}
                        >
                            Deelnemers
                        </Typography>
                        <Typography component="div" >
                            {`${over + under} personen`}
                        </Typography>
                    </div>

                </div>
                <Divider light />
                <div style={{
                    padding: '5px',
                }}>
                    <Typography
                        component='div'
                        style={{ fontWeight: 600 }}
                    >
                        Locatie
                    </Typography>
                    <Typography component="div" noWrap >
                        {location}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    </>)
}

export default SectionCourseInfo;