import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        inputRoot: {
            // fontSize: '16px'
        },
        labelRoot: {
            // fontSize: '16px',
            // color: "red",
            "&$labelFocused": {
                color: theme.palette.primary
            }
        },
        labelFocused: {},
        row: {
            display: 'flex',
            justifyContent: 'stretch'
        },
        button: {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '10px',
            margin: '5px',
            flex: 1
        },
    }),
);

const validationSchema = (length: number) => yup.object({
    code: yup.string()
            .required("Response Code is verplicht")
            .min(length, `Must be extractly ${length} characters`)
            .max(length, `Must be extractly ${length} characters`),
});

export default function AskInputResponseCodeDialog({
    open = false,
    length = 10,
    onCancel = () => { },
    onCompleted = () => { },
}: {
    open?: boolean,
    length?: number,
    onCancel?: () => void,
    onCompleted?: (code: string) => void,
}) {
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            code: ''
        },
        validateOnBlur: true,
        validationSchema: validationSchema(length),
        onSubmit: values => {
            onCompleted(values.code);
            formik.resetForm();
        }
    })

    const handleCancel = () => {
        formik.resetForm();
        onCancel();
    }

    return (
        <div>
            <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Response Code</DialogTitle>
                <DialogContent>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            margin="dense"
                            InputProps={{ classes: { root: classes.inputRoot } }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelRoot,
                                    focused: classes.labelFocused
                                }
                            }}
                            autoComplete="off"
                            autoFocus
                            id="code"
                            name="code"
                            label="Response Code"
                            onChange={formik.handleChange}
                            value={formik.values.code}
                            onBlur={formik.handleBlur}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                            helperText={formik.touched.code && formik.errors.code}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">Cancel</Button>
                    <Button disabled={(!formik.dirty || !formik.isValid)} onClick={() => formik.handleSubmit()} color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
