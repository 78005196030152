import React from 'react';
import { useHistory } from 'react-router-dom';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { Button, Card, CardContent, Grid, Hidden, Box, makeStyles, Theme, createStyles, Typography, FormGroup, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import Calendar from '../calendar/Calendar';
import { ISearchResultData } from '../../models/search';
import { useFilterContext, useReservationContext } from '../../store';
import CourseOverviewItem from './CourseOverviewItem';
import { PageLoadingContext } from '../layout/LoadingIndicator';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1), // 8px
            backgroundColor: theme.palette.background.paper,
        },
        notificationPanel: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            display: 'flex',
            alignItems: 'center'
        },
        stickyCalendar: {
            position: 'sticky',
            top: '80px',
            zIndex: 1,
            display: 'inline-block',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        calendarRoot: {
            width: '500px',
            marginTop: '20px',
            [theme.breakpoints.down('md')]: {
                width: '300px',
            },
        },

    }),
);

const ListContainer = ({
    items = [],
    fetchMoreData = () => { },
    onSelectItem = () => { },
    hasMore = true,
    isLoading = false,
}: {
    items?: ISearchResultData[],
    fetchMoreData: () => void,
    onSelectItem?: (item: ISearchResultData) => void,
    hasMore?: boolean,
    isLoading?: boolean,
}) => (<div>
        <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            style={{ overflow: 'hidden' }}
            hasMore={hasMore}
            loader={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {!isLoading && <CircularProgress />}
                </div>
            }
        >
            {items.map((item, index) => (
                <CourseOverviewItem
                    key={`${item.id}`}
                    item={item}
                    isFirst={index <= 0}
                    onSelect={() => onSelectItem(item)}
                />
            ))}
            {isLoading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </div>}
        </InfiniteScroll>
    </div>)

const Home = ({ width }: WithWidth) => {

    const history = useHistory();

    const classes = useStyles();

    const { setIsLoading } = React.useContext(PageLoadingContext);

    const { state: { loading: { get: isSearching, settingFilter: isSettingFilter }, result, filter, extra: { filterDate, vendorId: extraVendorId } }, setExtraFilterDate, removeExtraFilterDate, clear, search, toggleShowOnlyAvaliableOption, loadMore } = useFilterContext();

    React.useEffect(() => {
        setIsLoading(isSearching);
    }, [isSearching, setIsLoading]);

    const { prepare } = useReservationContext();

    const handleSelectCourse = async(item: ISearchResultData) => {
        await prepare(item, filter.over, filter.under, extraVendorId);
        history.push('/summary');
    }

    const locationFilterString = filter
        && filter.locations
        && filter.locations.length > 0
        && (filter.locations.length === 1
            ? filter.locations[0].surroundings || ''
            : `${filter.locations[0].surroundings || ''} en ${filter.locations.length - 1} andere`);

    const filterMessage = filter.locations.length === 0 ? `Alle interessante golfcursussen voor jou.` : `Alle interessante golfcursussen voor jou in ${locationFilterString}`

    const resetFilter = () => {
        clear();
        search();
    }

    return (<div className={classes.root}>
        <Grid container spacing={4}>
            <Grid item md={8} xs={12}>
                <div className={classes.notificationPanel}>
                    {result.total > 0
                        ? <Typography variant='h4'> {filterMessage} </Typography>
                        : <>
                            <Typography variant='h4'> Er zijn geen cursussen voor beschikbaar op basis van uw selectie </Typography>
                            <Button onClick={resetFilter} color="primary"> Zie alle beschikbaarheid </Button>
                        </>
                    }
                </div>
                <ListContainer isLoading={isSettingFilter} items={result.filterData} onSelectItem={handleSelectCourse} hasMore={result.hasMore && !isSettingFilter} fetchMoreData={() => loadMore()} />
            </Grid>
            <Hidden smDown>
                <Grid item md={4}>
                    <Box className={classes.stickyCalendar}>
                        <Box style={{ paddingBottom: '48px' }}>
                            <Box className={classes.calendarRoot}>
                                <Card style={{ borderRadius: '10px' }}>
                                    <CardContent>
                                        <Box>
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={<Switch name="showOnlyAvaiable" checked={filter.showOnlyAvailableSlot} onChange={toggleShowOnlyAvaliableOption} />}
                                                    label="Laat alleen beschikbare cursussen zien"
                                                    labelPlacement="start"
                                                />
                                            </FormGroup>
                                        </Box>
                                        <Calendar
                                            value={filterDate}
                                            showDoubleView={!['md', 'sm', 'xs'].includes(width)}
                                            markedDates={result.availableDates}
                                            onSelectDate={date => setExtraFilterDate(date)}
                                        />
                                        {filterDate && <Button color='primary' onClick={() => removeExtraFilterDate()} >Bekijk alle beschikbare datums</Button>}
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Hidden>
        </Grid>
    </div>);
}


export default withWidth()(Home);
