import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';

import { BookingWizardContext } from '../wizard/BookingWizard';
import StickyHeader from '../StickyHeader';
import StickyFooter from '../StickyFooter';

import { useFilterContext } from '../../store';
import { WithLayoutMobileProps } from '../layout/Layout.mobile';
import { withWizard, WithWizardProps } from '../wizard/withWizard';
import EmptySpace from '../layout/EmptySpace';
import { SlideTransition } from '../layout/Transitions';

interface Props extends WithWizardProps, WithLayoutMobileProps {
    title?: string,
    mode?: 'popup' | 'component',
    afterHandleNext?: () => void,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            margin: 'auto'
        },
        button: {
            paddingLeft: '10px',
            paddingRight: '10px',
            margin: '5px'
        },
        roundButton: {
            border: "1px solid lightgrey",
            borderRadius: '50%',
            padding: 0,
        }
    }),
);

const Item = ({ title, subtitle, number = 0, onDecrease, onIncrease }: {
    title?: string,
    subtitle?: string,
    number?: number,
    onIncrease?: () => void,
    onDecrease?: () => void
}) => {
    const classes = useStyles();

    return (
        <div style={{
            display: 'flex',
            padding: '5px',
            alignItems: 'center'
        }}>
            <div style={{ flex: 1}}>
                <Typography component="div" style={{ fontWeight: 'bold' }}>
                    {title}
                </Typography>
                <Typography component="div" color="textSecondary" style={{ overflowWrap: 'break-word' }}>
                    {subtitle}
                </Typography>
            </div>
            <EmptySpace width='50px' />
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifySelf: 'flex-end',
            }}>
                <IconButton
                    aria-label="delete"
                    className={classes.roundButton}
                    onClick={onDecrease}
                    disabled={number <= 0}
                >
                    <RemoveIcon style={{ fontSize: '20px' }} />
                </IconButton>
                <Typography component="span" variant="body2" style={{ margin: '0 10px 0 10px' }}>
                    {number}
                </Typography>
                <IconButton
                    aria-label="add"
                    className={classes.roundButton}
                    onClick={onIncrease}
                >
                    <AddIcon style={{ fontSize: '20px' }} />
                </IconButton>
            </div>
        </div>
    );
};

export const ParticipantSelection = ({
    containerStyle = {
        width: '100%'
    },
    over,
    under,
    onChange = () => { },
    button,
}: {
    over: number,
    under: number,
    containerStyle?: React.CSSProperties,
    onChange?: (over: number, under: number) => void,
    button?: React.ReactNode,
}) => (<div style={containerStyle}>
        <Item
            title="Volwassenen"
            subtitle="18 jaar of ouder"
            number={over}
            onIncrease={() => onChange(over + 1, under)}
            onDecrease={() => over > 0 && onChange(over - 1, under)}
        />
        <Divider light />
        <Item
            title="Jeugd"
            subtitle="leeftÿd tot 18 jaar"
            number={under}
            onIncrease={() => onChange(over, under + 1)}
            onDecrease={() => under > 0 && onChange(over, under - 1)}
        />
        <Divider light />
        <EmptySpace height='10px' />
        { button && button}

    </div>)

const InputParticipants = ({ setTitle = () => {}, isFocus, title, mode = 'component', afterHandleNext = () => { } }: Props) => {
    const { state: { filter }, setNrParticipant } = useFilterContext();

    const { goNext, goBack } = React.useContext(BookingWizardContext);
    const classes = useStyles();

    const handleNext = async () => {
        if (mode === 'component' && goNext) {
            goNext();
        }

        if (mode === 'popup') {
            afterHandleNext();
        }
    }

    React.useEffect(() => {
        if (isFocus) {
            setTitle(title || '');
        }
    }, [isFocus, setTitle, title]);

    const locationFilterString = filter
        && filter.locations
        && filter.locations.length > 0
        && (filter.locations.length === 1
            ? filter.locations[0].name
            : `${filter.locations[0].name} en ${filter.locations.length - 1} andere`);

    const courseFilterString = filter
        && filter.courses
        && filter.courses.length > 0
        && (filter.courses.length === 1
            ? filter.courses[0].name
            : `${filter.courses[0].name} en ${filter.courses.length - 1} andere`);

    return (<>
        {mode === 'component'
            && <StickyHeader
                start={
                    <IconButton onClick={() => goBack && goBack()} style={{ padding: '5px' }}>
                        <ArrowBackIosIcon style={{ fontSize: '20px' }} />
                    </IconButton>
                }
                component={<div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Typography component="div" variant="h6" align="center">
                        {locationFilterString}
                    </Typography>
                    <Typography component="div" variant="subtitle1" color="textSecondary" align="center">
                        {courseFilterString}
                    </Typography>
                </div>}
            />
        }

        <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            paddingTop: '10px'
        }}>
            <ParticipantSelection
                over={filter.over}
                under={filter.under}
                onChange={(over, under) => setNrParticipant(over, under)}
            />
        </div>

        <StickyFooter noSticky={mode === 'popup'}>
            <Button
                variant="contained" color="primary"
                startIcon={<SearchIcon />}
                onClick={handleNext}
                className={classes.button}
            >
                Zoeken
            </Button>
        </StickyFooter>
    </>)
}

export default InputParticipants;

export const NrParticipantSelectionStep = withWizard(InputParticipants);

export const NrParticipantFilterDialog = ({
    open = false,
    onClose = () => {}
}: {
    open?: boolean,
    onClose?: () => void,
}) => {

    const { state: { filter: { over, under } }, setNrParticipant } = useFilterContext();

    const [state, setState] = React.useState<{
        over: number,
        under: number,
    }>({
        over: 0,
        under: 0,
    });

    React.useEffect(() => {
        if (open) {
            setState(c => ({ ...c, over, under }));
        }
    }, [open]);

    const handleSubmit = () => {
        setNrParticipant(state.over, state.under);
        onClose();
    }

    return (<>

        <Dialog
            // fullScreen
            open={open}
            TransitionComponent={SlideTransition}
            disableBackdropClick
        >
            <DialogTitle style={{ padding: '5px' }}>
                <IconButton onClick={onClose} style={{ padding: '5px' }}>
                    <CloseIcon style={{ fontSize: '20px' }} />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers style={{
                minWidth: '300px',
                minHeight: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <ParticipantSelection
                        over={state.over}
                        under={state.under}
                        onChange={(overValue, underValue) => setState(c => ({ ...c, over: overValue, under: underValue, }))}
                    />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>Update</Button>
            </DialogActions>
        </Dialog>
    </>);
}