import { Dispatch } from 'redux';
import * as Model from './model';
import * as APIService from '../../api/golfbooking.service';
import { toast } from '../../components/layout/Notification';

export const fetch = () => async(dispatch: Dispatch<Model.Actions>, getState: () => { course: Model.IState }) => {

    const { course: { loading: { get } } } = getState();

    if (get) return;

    dispatch({ type: Model.ActionType.GET_LIST_REQUEST });

    try {
        const data = await APIService.getCourses();

        dispatch({
            type: Model.ActionType.GET_LIST_COMPLETED,
            payload: {
                data
            }
        });
    } catch (error: any) {

        dispatch({
            type: Model.ActionType.GET_LIST_COMPLETED,
            payload: {
                data: []
            }
        });

        toast.error('UNKNOWN ERROR: Cannot fetch data');

    }
    
}

export default fetch;