import { Dispatch } from 'redux';
import * as Model from './model';
import { ILocation } from '../../models/location';
import { ICourse } from '../../models/course';
import * as APIService from '../../api/golfbooking.service';
import { toast } from '../../components/layout/Notification';

export const clear = () => (dispatch: Dispatch<Model.Actions>) => {
    dispatch({
        type: Model.ActionType.CLEAR,
    });
}

export const setFilterCourse = (items: ICourse[] = [], onChanged = () => {}) => async(dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    const { filter: { filter: { courses } } } = getState();

    let isUpdated = true;

    if (items.length === courses.length) {
        const list1 = items.map(i => i.id);
        const list2 = courses.map(i => i.id);

        const diff = list1.filter(i => list2.includes(i));

        if (diff.length === list1.length) {
            isUpdated = false;
        };
    }

    if (isUpdated) {
        dispatch({
            type: Model.ActionType.SET_COURSE_FILTER,
            payload: items,
        });

        onChanged();
    }
}

export const setFilterLocation = (items: ILocation[], onChanged = () => {}) => (dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    const { filter: { filter: { locations } } } = getState();

    let isUpdated = true;

    if (items.length === locations.length) {
        const list1 = items.map(i => i.id);
        const list2 = locations.map(i => i.id);

        const diff = list1.filter(i => list2.includes(i));

        if (diff.length === list1.length) {
            isUpdated = false;
        };
    }

    if (isUpdated) {
        dispatch({
            type: Model.ActionType.SET_LOCATION_FILTER,
            payload: items,
        });
        
        onChanged();
    }
}

const doSearch = async (filterLocations: ILocation[], filterCourses: ICourse[], onlyAvailable: boolean, extraFilterDate?: Date, extraNextPage?: number) => {
    const courseFilter = filterCourses.reduce((a, c) => `${a}&in_courses[]=${c.id}` , '');
    const locationFilter = filterLocations.reduce((a, c) => `${a}&in_locations[]=${c.id}` , '');
    const availableFilter = `onlyAvailable=${onlyAvailable}`;

    let fromDateFilter = null;
    let toDateFilter = null;

    if(extraFilterDate) {
        fromDateFilter = `fromDate=${extraFilterDate.toISOString()}`;
        toDateFilter = `toDate=${(new Date(extraFilterDate.getFullYear(), extraFilterDate.getMonth(), extraFilterDate.getDate(), 23, 59, 59)).toISOString()}`;
    }

    const data = await APIService.search(courseFilter, locationFilter, availableFilter, fromDateFilter, toDateFilter, extraNextPage);

    return data;
}

export const search = () => async (dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    const { filter: { loading: { get, getMore }, filter: { locations, courses, showOnlyAvailableSlot } } } = getState();

    if (get || getMore) return;

    dispatch({ type: Model.ActionType.SEARCH_REQUEST });

    dispatch({
        type: Model.ActionType.REMOVE_EXTRA_FILTER_DATE
    });
    
    try {
        const data = await doSearch(locations, courses, showOnlyAvailableSlot, );
        
        dispatch({
            type: Model.ActionType.SEARCH_COMPLETED,
            payload: data
        });

        dispatch({
            type: Model.ActionType.REMOVE_EXTRA_FILTER_DATE
        });
        
    } catch (error: any) {
        dispatch({
            type: Model.ActionType.SEARCH_COMPLETED,
            payload: {
                availableDates: [],
                total: 0,
                page: {
                    total: 0,
                    page: 0,
                    pageSize: 0,
                    data: []
                }
            }
        });

        toast.error('UNKNOWN ERROR: Cannot fetch data');
    }

}

export const loadMore = () => async (dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    const { filter: { loading: { get, getMore }, result: { page: { page: currentPage } } , filter: { locations, courses, showOnlyAvailableSlot }, extra: { filterDate } } } = getState();
    
    if (get || getMore) return;

    dispatch({ type: Model.ActionType.LOAD_MORE_REQUEST });
    
    try {

        const data = await doSearch(locations, courses, showOnlyAvailableSlot, filterDate, currentPage + 1);

        dispatch({
            type: Model.ActionType.LOAD_MORE_COMPLETED,
            payload: data.page,
        });
        
    } catch (error: any) {
        dispatch({
            type: Model.ActionType.LOAD_MORE_COMPLETED,
            payload: {
                total: 0,
                pageSize: 0,
                page: 0,
                data: []
            }
        });

        toast.error('UNKNOWN ERROR: Cannot fetch data');
    }

}

export const setNrParticipant = (over: number, under: number) => (dispatch: Dispatch<Model.Actions>) => {
    dispatch({
        type: Model.ActionType.SET_PARTICIPANT,
        payload: {
            over,
            under,
        },
    });
}

export const increaseNrOver = () => (dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    
    const { filter: { filter: { over, under } } } = getState();

    dispatch({
        type: Model.ActionType.SET_PARTICIPANT,
        payload: {
            over: over + 1,
            under,
        },
    });
}

export const decreaseNrOver = () => (dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    const { filter: { filter: { over, under } } } = getState();
    dispatch({
        type: Model.ActionType.SET_PARTICIPANT,
        payload: {
            over: over > 0 ? over - 1 : over,
            under,
        },
    });
}

export const increaseNrUnder = () => (dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    const { filter: { filter: { over, under } } } = getState();
    dispatch({
        type: Model.ActionType.SET_PARTICIPANT,
        payload: {
            over,
            under: under + 1,
        },
    });
}

export const decreaseNrUnder = () => (dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    const { filter: { filter: { over, under } } } = getState();
    dispatch({
        type: Model.ActionType.SET_PARTICIPANT,
        payload: {
            over,
            under: under > 0 ? under - 1 : under,
        },
    });
}

export const toggleShowOnlyAvaliableOption = () => async (dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    const { filter: { loading: { get, getMore }, filter: { locations, courses, showOnlyAvailableSlot }, extra: { filterDate } } } = getState();

    if (get || getMore) return;

    dispatch({
        type: Model.ActionType.SET_ONLY_AVAILABLE,
        payload: !showOnlyAvailableSlot,
    });

    dispatch({ type: Model.ActionType.SEARCH_REQUEST });
    
    try {
        const data = await doSearch(locations, courses, !showOnlyAvailableSlot, filterDate, );
        
        dispatch({
            type: Model.ActionType.SEARCH_COMPLETED,
            payload: data
        });

        dispatch({
            type: Model.ActionType.REMOVE_EXTRA_FILTER_DATE
        });
        
    } catch (error: any) {
        dispatch({
            type: Model.ActionType.SEARCH_COMPLETED,
            payload: {
                availableDates: [],
                total: 0,
                page: {
                    total: 0,
                    page: 0,
                    pageSize: 0,
                    data: []
                }
            }
        });

        toast.error('UNKNOWN ERROR: Cannot fetch data');
    }
}

export const setExtraFilterDate = (filterDate: Date) => async (dispatch: Dispatch<Model.Actions>, getState: () => { filter: Model.IState }) => {
    
    const { filter: { filter: { locations, courses, showOnlyAvailableSlot } } } = getState();

    dispatch({ type: Model.ActionType.ADD_FILTER_REQUEST });
    
    try {
        const data = await doSearch(locations, courses, showOnlyAvailableSlot, filterDate, );
        
        dispatch({
            type: Model.ActionType.SET_EXTRA_FILTER_DATE,
            payload: {
                date: filterDate,
                data: data.page,
            },
        });
    
        
    } catch (error: any) {
        dispatch({
            type: Model.ActionType.SET_EXTRA_FILTER_DATE,
            payload: {
                date: filterDate,
                data: {
                    total: 0,
                    pageSize: 0,
                    page: 0,
                    data: []
                },
            },
        });

        toast.error('UNKNOWN ERROR: Cannot fetch data');
    }
}

export const setExtraVendorId = (vendorId: string) => (dispatch: Dispatch<Model.Actions>) => {
    dispatch({
        type: Model.ActionType.SET_EXTRA_VENDOR_ID,
        payload: vendorId,
    });
}

export const removeExtraFilterDate = () => (dispatch: Dispatch<Model.Actions>) => {
    dispatch({
        type: Model.ActionType.REMOVE_EXTRA_FILTER_DATE
    });
}
