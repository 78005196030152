/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

export const PageLoadingContext = React.createContext<LoadingProps>({ isLoading: false, setIsLoading: () => {} });

interface LoadingProps {
    isLoading: boolean,
    setIsLoading: (isLoading: boolean) => void,
}

export const LoadingSpinner = () => <CircularProgress />

export const FullScreenLoading = ({ isLoading = false }: Pick<LoadingProps, 'isLoading'>) => {
    const classes = useStyles();
    return <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
    </Backdrop>
}

export const withFullScreenLoading = <P extends object>(
    Component: React.ComponentType<P>,
): React.FC<P & Partial<LoadingProps>> => ({ isLoading: initLoading, ...props }) => {

    const [isLoading, setIsLoading] = React.useState(initLoading || false);

    const callSetLoading = React.useCallback((isLoadingValue: boolean) => {
        setIsLoading(isLoadingValue);
    }, [setIsLoading] );

    return (<>
        <FullScreenLoading isLoading={isLoading} />
        <PageLoadingContext.Provider value={{ isLoading, setIsLoading: callSetLoading }}>
            <Component {...props as P} />
        </PageLoadingContext.Provider>
        
    </>);
}

export const withLoading = <P extends object>(
    Component: React.ComponentType<P>,
): React.FC<P & Pick<LoadingProps, 'isLoading'>> => ({ isLoading, ...props }) => isLoading ? <LoadingSpinner /> : <Component {...props as P} />;