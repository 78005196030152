import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Card, CardContent, Typography, CardMedia, List, ListItem, ListItemIcon, Checkbox, Divider, Theme, createStyles, ListItemText, Dialog, DialogTitle, IconButton, DialogContent, Button, ListItemAvatar, Avatar, DialogActions } from '@material-ui/core';

import { BookingWizardContext } from '../wizard/BookingWizard';
import StickyHeader from '../StickyHeader';
import { ICourse } from '../../models/course';
import { useCourseContext, useFilterContext } from '../../store';
import { WithLayoutMobileProps } from '../layout/Layout.mobile';
import { withWizard, WithWizardProps } from '../wizard/withWizard';
import { SlideTransition } from '../layout/Transitions';
import { PageLoadingContext, withLoading } from '../layout/LoadingIndicator';

interface Props extends WithWizardProps, WithLayoutMobileProps {
    title?: string,
    mode?: 'popup' | 'component',
    afterHandleNext?: () => void,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            // margin: 'auto',
        },
        card: {
            // maxWidth: '200px',
            borderRadius: '20px',
            // height: '80px',
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer'
        },
        cardContent: {
            // flex: '1 0 auto',
        },
        imageCover: {
            minWidth: '60px',
            margin: '10px',
            borderRadius: '10px',
            backgroundSize: 'contain',
        },
        imageCover2: {
            minWidth: '60px',
            margin: '10px',
            borderRadius: '10px',
        },
        tickCheck: {
            transform: 'scale(1)'
        },
    }),
);

export const CourseSelection = ({ data = [], onSelectingItem = () => { } }: { data?: ICourse[], onSelectingItem?: (item: ICourse) => void }) => {

    const classes = useStyles();

    return (<>
        <div style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            margin: '5px'
        }}>
            {data.map(item => <Card key={item.id} className={classes.card} onClick={() => onSelectingItem(item)}>
                <CardContent className={classes.cardContent}>
                    <Typography component="div" style={{ fontWeight: 600 }}>
                        {item.name}
                    </Typography>
                    <Typography component="div" color="textSecondary" style={{
                        maxHeight: '60px'
                    }}>
                        {item.description}
                    </Typography>
                </CardContent>
                <CardMedia
                    className={classes.imageCover}
                    image={`${item.imageURL}`}
                    title={item.name}
                />
            </Card>)}
        </div>
    </>);
}

export const CourseMultipleSelection = withLoading(
    ({
        data = [],
        checkedItems = [],
        onItemSelected = () => { },
        button,
    }: {
        data?: ICourse[],
        checkedItems?: string[],
        onItemSelected?: (item: string) => void,
        button?: React.ReactNode,
    }
    ) => {

        const classes = useStyles();

        return (<>
            <List dense className={classes.container}>
                <ListItem button>
                    <ListItemIcon>
                        <Checkbox
                            edge="end"
                            onChange={() => onItemSelected(`checkbox-all`)}
                            checked={checkedItems.includes(`checkbox-all`)}
                            className={classes.tickCheck}
                        />
                    </ListItemIcon>
                    <ListItemText onClick={() => onItemSelected(`checkbox-all`)}>
                        <Typography component="div" style={{ fontWeight: 600 }}>
                            (de)selecteer alle cursus(sen)
                        </Typography>
                    </ListItemText>
                </ListItem>
                <Divider light />
                {data.map((item) => {
                    const labelId = `checkbox-list-secondary-label-${item.id}`;
                    return (
                        <ListItem key={item.id} button style={{
                            marginTop: '10px'
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="end"
                                    onChange={() => onItemSelected(item.id)}
                                    checked={checkedItems.includes(item.id)}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    className={classes.tickCheck}
                                />
                            </ListItemIcon>
                            <ListItemText onClick={() => onItemSelected(item.id)}>
                                <Typography component="div" style={{ fontWeight: 600 }}>
                                    {item.name}
                                </Typography>
                            </ListItemText>
                            <ListItemAvatar style={{ marginLeft: '5px' }}>
                                <Avatar src={`${item.imageURL}`} onClick={() => onItemSelected(item.id)} />
                            </ListItemAvatar>

                        </ListItem>
                    );
                })}
            </List>
            {button && button}
        </>);
    }
);

export const useCourseSelection = (lazyLoad: boolean = true) => {
    const { state: { loading: { get: getLoading }, list: data }, fetch } = useCourseContext();

    const [checked, setChecked] = React.useState<string[]>([]);

    const handleToggle = (itemId: string) => {
        let newChecked: string[] = checked.filter(i => i !== `checkbox-all`);
        if (itemId === `checkbox-all`) {
            if (checked.includes(itemId)) {
                newChecked = [];
            } else {
                newChecked = data.map(i => i.id);
            }
        } else if (newChecked.includes(itemId)) {
                newChecked = newChecked.filter(i => i !== itemId);
            } else {
                newChecked = [...newChecked, itemId];
            }

        if (newChecked.length === data.length) {
            setChecked([...newChecked, `checkbox-all`]);
        } else {
            setChecked(newChecked);
        }

    };

    React.useEffect(() => {
        if (lazyLoad) {
            fetch();
        }
    }, []);

    const initCheck = (selected: string[]) => {
        if (selected.length === data.length && selected.length > 0) {

            setChecked([...selected, `checkbox-all`]);
        } else {
            setChecked([...selected]);
        }
    }

    return {
        data,
        checked,
        handleToggle,
        initCheck,
        fetch,
        getLoading
    }
}

export const CourseSelectionStep = withWizard(
    ({ setTitle, title, isFocus }: Props) => {
        const { state: { loading: { get: getLoading }, list: data }, fetch } = useCourseContext();
        const { setFilterCourse } = useFilterContext();

        const { setIsLoading } = React.useContext(PageLoadingContext);

        const { goNext } = React.useContext(BookingWizardContext);

        React.useEffect(() => {
            if (isFocus && setTitle) {
                setTitle(title || '');
            }

            if (isFocus && data.length === 0) {
                fetch();
            }

            if (isFocus) {
                setIsLoading(getLoading);
            }
        }, [isFocus, getLoading, setIsLoading]);

        const handleNext = async (selectedItem: ICourse) => {
            setFilterCourse([selectedItem]);
            if (goNext) {
                goNext();
            }
        }

        return (<>
            <StickyHeader />
            <CourseSelection data={data} onSelectingItem={handleNext} />
        </>);
    }
);

export const CourseFilterDialog = ({
    open = false,
    onClose = () => { }
}: {
    open?: boolean,
    onClose?: () => void,
}) => {

    const { state: { filter: { courses: filterCourses }, }, setFilterCourse, search } = useFilterContext();

    const { getLoading, data, checked, handleToggle, initCheck, fetch } = useCourseSelection(false);

    React.useEffect(() => {
        if (open) {
            handleOpen();
        }
    }, [open]);

    const handleOpen = async () => {
        if (data.length === 0) {
            await fetch();
        }
        initCheck(filterCourses.map(i => i.id));
    }


    const handleSubmit = () => {
        const items = checked;
        const selectedItems = data.filter(i => items.includes(i.id));
        setFilterCourse(selectedItems, search);
        onClose();
    }

    return (<>

        <Dialog
            // fullScreen
            open={open}
            TransitionComponent={SlideTransition}
            disableBackdropClick
        >
            <DialogTitle style={{ padding: '5px' }}>
                <IconButton onClick={onClose} style={{ padding: '5px' }}>
                    <CloseIcon style={{ fontSize: '20px' }} />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers style={{
                minWidth: '300px',
                minHeight: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{ maxHeight: '600px' }}>
                    <CourseMultipleSelection
                        isLoading={getLoading}
                        data={data}
                        checkedItems={checked}
                        onItemSelected={handleToggle}
                    />
                </div>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>Update</Button>
            </DialogActions>
        </Dialog>
    </>);
}
