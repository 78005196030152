import { Reducer } from 'redux';
import * as MODEL from './model';

const reducer: Reducer<MODEL.IState> = (state: MODEL.IState = MODEL.initialState, action: MODEL.Actions) => {
    switch (action.type) {
        case MODEL.ActionType.SEARCH_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: true,
                }
            }
        case MODEL.ActionType.SEARCH_COMPLETED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    get: false,
                },
                result: {
                    ...action.payload,
                    data: [...action.payload.page.data],
                    filterData: [...action.payload.page.data],
                    hasMore: action.payload.page.data.length > 0
                }
            }
        case MODEL.ActionType.LOAD_MORE_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getMore: true,
                }
            }
        case MODEL.ActionType.LOAD_MORE_COMPLETED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    getMore: false,
                },
                result: {
                    ...state.result,
                    page: action.payload.data.length > 0 ? { ...action.payload } : { ...state.result.page },
                    data: [...state.result.data, ...action.payload.data],
                    filterData: [...state.result.data, ...action.payload.data],
                    hasMore: action.payload.data.length > 0
                }
            }
        case MODEL.ActionType.SET_COURSE_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    courses: [...action.payload],
                }
            }
        case MODEL.ActionType.SET_LOCATION_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    locations: [...action.payload],
                }
            }
        case MODEL.ActionType.SET_PARTICIPANT:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    over: action.payload.over,
                    under: action.payload.under,
                }
            }
        case MODEL.ActionType.SET_ONLY_AVAILABLE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    showOnlyAvailableSlot: action.payload,
                }
            }
        case MODEL.ActionType.ADD_FILTER_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    settingFilter: true,
                },
                result: {
                    ...state.result,
                    filterData: [],
                }
            }
        case MODEL.ActionType.SET_EXTRA_FILTER_DATE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    settingFilter: false,
                },
                extra: {
                    ...state.extra,
                    filterDate: action.payload.date,
                },
                result: {
                    ...state.result,
                    filterData: [...action.payload.data.data]
                }
            }
        case MODEL.ActionType.REMOVE_EXTRA_FILTER_DATE:
            return {
                ...state,
                extra: {
                    ...state.extra,
                    filterDate: undefined,
                },
                result: {
                    ...state.result,
                    filterData: [...state.result.data],
                    hasMore: true,
                }
            }
        case MODEL.ActionType.SET_EXTRA_VENDOR_ID:
            return {
                ...state,
                extra: {
                    ...state.extra,
                    vendorId: action.payload,
                }
            }
        case MODEL.ActionType.CLEAR:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    locations: [],
                    courses: [],
                },
                extra: {
                    ...state.extra,
                    filterDate: undefined,
                    vendorId: undefined,
                }
            }
        default:
            return state;
    }
}

export default reducer;
