import React from "react";
import { Avatar, Button, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CourseFullContentDialog from "../CourseFullContentDialog";
import { useDevice } from "../../hooks/device";


interface Props {
    logo?: string,
    caption?: string,
    detail?: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        linkButton: {
            padding: theme.spacing(0),
        },
    }),
);

const SectionProgram = ({
    logo,
    caption = '',
    detail = ''
}: Props) => {

    const classes = useStyles();
    const { isBrowser } = useDevice();
    const [open, setOpen] = React.useState(false);

    return (<>
        <div style={{
            display: 'flex',
            padding: '8px'
        }} >
            <div style={{
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
            }}>
                <Typography component="div" variant="h6">
                    {caption}
                </Typography>
                <Button onClick={() => setOpen(true)} className={classes.linkButton}>
                    <Typography component="div" variant="subtitle2" style={{
                        textDecorationLine: 'underline',
                    }}>
                        BEKIJK HET VOLLEDIGE PROGRAMMA
                    </Typography>
                </Button>
            </div>
            <div>
                <Avatar alt="Remy Sharp" src={logo} style={{
                    width: '60px',
                    height: '60px',
                }} />
            </div>

        </div>
        <CourseFullContentDialog isHtml fullScreen={!isBrowser} open={open} onClose={() => setOpen(false)} title="VOLLEDIG PROGRAMMA" data={detail} />
    </>)
}

export default SectionProgram;