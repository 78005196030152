import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, ListItemAvatar, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { BookingWizardContext } from '../wizard/BookingWizard';
import StickyHeader from '../StickyHeader';
import StickyFooter from '../StickyFooter';
import { ILocation } from '../../models/location';
import { useFilterContext, useLocationContext } from '../../store';
import { WithLayoutMobileProps } from '../layout/Layout.mobile';
import { withWizard, WithWizardProps } from '../wizard/withWizard';
import { SlideTransition } from '../layout/Transitions';
import { PageLoadingContext, withLoading } from '../layout/LoadingIndicator';

interface Props extends WithWizardProps, WithLayoutMobileProps {
    title?: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            // margin: 'auto',
        },
        tickCheck: {
            transform: 'scale(1)'
        },
        button: {
            paddingLeft: '10px',
            paddingRight: '10px',
            margin: '5px'
        }
    }),
);

export const LocationSelection = withLoading(
    ({
        data = [],
        checkedItems = [],
        onItemSelected = () => { },
        button
    }: {
        data?: ILocation[],
        checkedItems?: string[],
        onItemSelected?: (item: string) => void,
        button?: React.ReactNode,
    }) => {

        const classes = useStyles();

        return (<>
            <List dense className={classes.root}>
                <ListItem button>
                    <ListItemIcon>
                        <Checkbox
                            edge="end"
                            onChange={() => onItemSelected(`checkbox-all`)}
                            checked={checkedItems.includes(`checkbox-all`)}
                            className={classes.tickCheck}
                        />
                    </ListItemIcon>
                    <ListItemText onClick={() => onItemSelected(`checkbox-all`)}>
                        <Typography component="div" style={{ fontWeight: 600 }}>
                            (de)selecteer alle locaties
                        </Typography>
                    </ListItemText>
                </ListItem>
                <Divider light />
                {data.map((item) => {
                    const labelId = `checkbox-list-secondary-label-${item.id}`;
                    return (
                        <ListItem key={item.id} button style={{ marginTop: '10px' }} >
                            <ListItemIcon>
                                <Checkbox
                                    edge="end"
                                    onChange={() => onItemSelected(item.id)}
                                    checked={checkedItems.includes(item.id)}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    className={classes.tickCheck}
                                />
                            </ListItemIcon>
                            <ListItemText onClick={() => onItemSelected(item.id)}>
                                <Typography component="div" style={{ fontWeight: 600 }}>
                                    {item.name}
                                </Typography>
                            </ListItemText>
                            <ListItemAvatar style={{ marginLeft: '5px' }}>
                                <Avatar src={`${item.logoURL}`} onClick={() => onItemSelected(item.id)} />
                            </ListItemAvatar>

                        </ListItem>
                    );
                })}
            </List>
            <Divider light />
            { button && button}

        </>);
    }
);

export const useLocationSelection = (lazyLoad: boolean = true) => {
    const { state: { loading: { get: getLoading }, list: data }, fetch } = useLocationContext();

    const [checked, setChecked] = React.useState<string[]>([]);

    const handleToggle = (itemId: string) => {
        let newChecked: string[] = checked.filter(i => i !== `checkbox-all`);
        if (itemId === `checkbox-all`) {
            if (checked.includes(itemId)) {
                newChecked = [];
            } else {
                newChecked = data.map(i => i.id);
            }
        } else if (newChecked.includes(itemId)) {
                newChecked = newChecked.filter(i => i !== itemId);
            } else {
                newChecked = [...newChecked, itemId];
            }

        if (newChecked.length === data.length) {
            setChecked([...newChecked, `checkbox-all`]);
        } else {
            setChecked(newChecked);
        }

    };

    React.useEffect(() => {
        if (lazyLoad) {
            fetch();
        }
    }, []);
    const initCheck = (selected: string[]) => {
        if (selected.length === data.length && selected.length > 0) {

            setChecked([...selected, `checkbox-all`]);
        } else {
            setChecked([...selected]);
        }
    }

    return {
        data,
        checked,
        handleToggle,
        initCheck,
        fetch,
        getLoading
    }
}

export const LocationSelectionStep = withWizard(({ setTitle = () => { }, isFocus, title }: Props) => {

    const { state: { filter }, setFilterLocation } = useFilterContext();

    const { getLoading, data, checked, handleToggle, initCheck, fetch } = useLocationSelection(false);

    const { setIsLoading } = React.useContext(PageLoadingContext);

    const { goNext, goBack } = React.useContext(BookingWizardContext);
    const classes = useStyles();

    React.useEffect(() => {
        if (isFocus && setTitle) {
            setTitle(title || '');
        }

        if (isFocus && data.length === 0) {
            fetch();
        }

        if (isFocus) {
            setTitle(title || '');
        }

    }, [isFocus, getLoading, setIsLoading, setTitle]);

    React.useLayoutEffect(() => {
        if (filter && filter.locations) {
            const selectedItems = [...filter.locations.map(i => i.id)];
            initCheck(selectedItems);
        }
    }, []);

    const courseFilterString = filter
        && filter.courses
        && filter.courses.length > 0
        && (filter.courses.length === 1
            ? filter.courses[0].name
            : `${filter.courses[0].name} en ${filter.courses.length - 1} andere`);

    const handleNext = async () => {
        const selectedItems = data.filter(i => checked.includes(i.id));
        setFilterLocation(selectedItems);
        if (goNext) {
            goNext();
        }
    }

    return (<>
        <StickyHeader
            start={
                <IconButton onClick={() => goBack && goBack()} style={{ padding: '5px' }}>
                    <ArrowBackIosIcon style={{ fontSize: '20px' }} />
                </IconButton>
            }
            component={<>
                {filter.courses && <Typography component="div" variant="h6" align="center" style={{ flex: 1 }}>
                    {courseFilterString}
                </Typography>}
            </>}
        />


        <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
        }}>
            <LocationSelection isLoading={false} data={data} checkedItems={checked} onItemSelected={handleToggle} />
        </div>

        <StickyFooter>
            <Button
                variant="contained" color="primary"
                onClick={() => handleNext()}
                className={classes.button}
            >
                Volgende
            </Button>
        </StickyFooter>
    </>);
});


export const LocationFilterDialog = ({
    open = false,
    onClose = () => { }
}: {
    open?: boolean,
    onClose?: () => void,
}) => {

    const { state: { filter: { locations: filterLocations }, }, setFilterLocation, search } = useFilterContext();

    const { data, getLoading, checked, handleToggle, fetch, initCheck } = useLocationSelection(false);

    React.useEffect(() => {
        if (open) {
            handleOpen();
        }
    }, [open]);

    const handleOpen = async () => {
        if (data.length === 0) {
            await fetch();
        }
        initCheck(filterLocations.map(i => i.id));
    }

    const handleSubmit = () => {
        const items = checked;
        const selectedItems = data.filter(i => items.includes(i.id));
        setFilterLocation(selectedItems, search);
        onClose();
    }

    return (<>

        <Dialog
            // fullScreen
            open={open}
            TransitionComponent={SlideTransition}
            disableBackdropClick
        >
            <DialogTitle style={{ padding: '5px' }}>
                <IconButton onClick={onClose} style={{ padding: '5px' }}>
                    <CloseIcon style={{ fontSize: '20px' }} />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers style={{
                minWidth: '300px',
                minHeight: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{ maxHeight: '600px' }}>
                    <LocationSelection
                        isLoading={getLoading}
                        data={data}
                        checkedItems={checked}
                        onItemSelected={handleToggle}
                    />
                </div>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>Update</Button>
            </DialogActions>
        </Dialog>
    </>);
}