import React from "react";
import { useHistory } from "react-router-dom";
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfiniteScroll from "react-infinite-scroll-component";

import Calendar from '../calendar/Calendar';
import { WithLayoutMobileProps } from "../layout/Layout.mobile";
import { BookingWizardContext } from '../wizard/BookingWizard';
import StickyHeader from '../StickyHeader';

import SearchFilter from "./SearchFilter";
import { ISearchResultData } from "../../models/search";
import { useFilterContext, useReservationContext } from "../../store";
import { withWizard, WithWizardProps } from "../wizard/withWizard";
import { PageLoadingContext } from "../layout/LoadingIndicator";
import SearchResultItemDefault from "./SearchResultItem";


interface Props extends WithWizardProps, WithLayoutMobileProps { }

const SearchResult = ({ setTitle, isFocus }: Props) => {
    const history = useHistory();

    const { state: { loading: { get: isSearching, settingFilter: isSettingFilter }, filter, result, extra: { filterDate, vendorId: extraVendorId } }, search, setNrParticipant, setFilterCourse, setFilterLocation, setExtraFilterDate, removeExtraFilterDate, loadMore } = useFilterContext();

    const { prepare } = useReservationContext();

    const { setIsLoading } = React.useContext(PageLoadingContext);

    const { goBack } = React.useContext(BookingWizardContext);

    const [filterVisibility, setFilterVisibility] = React.useState(false);

    React.useEffect(() => {
        if (isFocus && setTitle) {
            setTitle('');
        }

        if (isFocus) {
            setIsLoading(isSearching);
        }
    }, [isFocus, isSearching, setTitle, setIsLoading]);

    React.useEffect(() => {
        if (isFocus) {
            search();
        }
    }, [filter.courses, filter.locations]);

    const handleNext = async (item: ISearchResultData) => {
        await prepare(item, filter.over, filter.under, extraVendorId);
        history.push('/summary');
    }

    const locationFilterString = filter
        && filter.locations
        && filter.locations.length > 0
        && (filter.locations.length === 1
            ? filter.locations[0].name
            : `${filter.locations[0].name} en ${filter.locations.length - 1} andere`);

    const courseFilterString = filter
        && filter.courses
        && filter.courses.length > 0
        && (filter.courses.length === 1
            ? filter.courses[0].name
            : `${filter.courses[0].name} en ${filter.courses.length - 1} andere`);

    return (<>
        <StickyHeader
            start={<IconButton
                onClick={() => goBack && goBack()}
                style={{
                    padding: '5px'
                }}
            >
                <ArrowBackIosIcon style={{
                    fontSize: '20px',
                }} />
            </IconButton>}
            component={<div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {filterVisibility
                    ?
                    <Typography component="div" variant="h6" align="center">
                        Je zoekopdracht aanpassen
                    </Typography>
                    : <>
                        <Typography component="div" variant="h6" align="center">
                            {locationFilterString}
                        </Typography>
                        <Typography component="div" variant="subtitle1" color="textSecondary" align="center">
                            {courseFilterString
                                ? `${courseFilterString} - ${filter.over + filter.under} deelnem`
                                : `${filter.over + filter.under} deelnem`
                            }
                        </Typography>
                    </>
                }


            </div>}
            end={<>
                <IconButton
                    onClick={() => setFilterVisibility(c => !c)}
                    style={{
                        padding: '5px'
                    }}
                >
                    {filterVisibility
                        ? <CloseIcon style={{
                            fontSize: '20px',
                        }} />
                        : <TuneIcon style={{
                            fontSize: '20px',
                        }} />
                    }
                </IconButton>
            </>}
        />
        { filterVisibility && <SearchFilter
            filter={filter}
            onChangeNr={setNrParticipant}
            onChangeCourse={items => setFilterCourse(items)}
            onChangeLocations={items => setFilterLocation(items)}
        />}
        <Grid container spacing={1} style={{ width: '100%' }}>
            <Grid item xs={12}>
                <Card style={{ borderRadius: '10px', margin: '4px' }}>
                    <CardContent>
                        <Calendar
                            value={filterDate}
                            markedDates={result.availableDates}
                            onSelectDate={date => setExtraFilterDate(date)}
                        />
                        {filterDate && <Button color='primary' onClick={() => removeExtraFilterDate()} >Bekijk alle beschikbare datums</Button>}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <InfiniteScroll
                    dataLength={result.filterData.length}
                    next={() => loadMore()}
                    style={{ overflow: 'hidden' }}
                    hasMore={result.hasMore && !isSettingFilter}
                    loader={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {!isSettingFilter && <CircularProgress />}
                        </div>
                    }
                >
                    {result.filterData.map(item => (
                        <SearchResultItemDefault key={`${item.id}`} item={item} onSelect={() => handleNext(item)} />
                    ))}
                    {isSettingFilter && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress />
                    </div>}
                </InfiniteScroll>
            </Grid>
        </Grid>
    </>)
}

export default SearchResult;

export const SearchResultStep = withWizard(SearchResult);