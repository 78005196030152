import React from 'react';
import ReactCalendar, { CalendarProps } from 'react-calendar';
import './Calendar.css';
import moment from 'moment';

const Calendar = ({
    showDoubleView,
    markedDates=[],
    onSelectDate = () => {},
    value,
}: CalendarProps & {
    markedDates?: Date[],
    onSelectDate?:(date: Date) => void
}) => {
    
    const handle = (date: Date) => {
        const isIncluding = markedDates.map(x => moment(x).format('YYYY-MM-DD')).includes(moment(date).format('YYYY-MM-DD'));
        if (isIncluding) {
            onSelectDate(date);
        }
    }

    return (<>
        <ReactCalendar
            showDoubleView={showDoubleView}
            value={value || [new Date()]}
            locale="nl"
            tileClassName={
                ({ date }) => {
                    if (markedDates.find(x => moment(x).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))) {
                        return ['react-calendar__tile--marked'];
                    }
                    return null;
                }
            }

        onClickDay={handle}
    />
    </>)
}

export default Calendar;
