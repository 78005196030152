import React from 'react';
import { Button, TextField, Typography, IconButton, CircularProgress, Card, CardContent } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import * as APIService from '../../api/golfbooking.service';
import { IVoucher } from "../../models/voucherCode";
import AskInputResponseCodeDialog from '../AskInputResponseCode';
import { toast } from '../layout/Notification';
import { ICourse } from '../../models/course';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputRoot: {
            borderRadius: '10px'
        },
        labelRoot: {
            "&$labelFocused": {
                color: theme.palette.primary
            }
        },
        labelFocused: {},
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonProgress: {
            // color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
);


const PromotionItem = ({
    data,
    onRemove = () => { }
}: {
    data: IVoucher,
    onRemove?: () => void
}) => {

    const { code, discountAmount } = data;

    return (<div style={{
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'center',
        border: '1px black solid',
        borderRadius: '10px',
        margin: '2px 0 2px 0',
        padding: '2px 4px 2px 4px', // top right bottom left
    }}>
        <Typography variant="body1" component="div" style={{
            flex: 1,
            margin: '0'
        }}>
            {code}
        </Typography>
        <Typography variant="body1" component="div" style={{
            margin: '0'
        }}>
            {`- \u20AC${discountAmount}, -`}
        </Typography>
        <IconButton
            aria-label="remove a voucher"
            onClick={onRemove}
            style={{
                padding: '5px'
            }}
        >
            <CloseIcon style={{
                color: 'red',
                fontSize: '20px',
            }} />
        </IconButton>
    </div>);
}

const SectionPromotionCode = ({
    data = [],
    selectedCourse,
    onAddCode = () => { },
    onRemoveCode = () => { },
    disabled = false,
}: {
    data?: IVoucher[],
    selectedCourse: ICourse,
    onAddCode?: (voucher: IVoucher, minNrParticipant: number) => void,
    onRemoveCode?: (codeId: string) => void,
    disabled?: boolean
}) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = React.useState(false);
    const [inputCode, setInputCode] = React.useState<string>('');
    const [inputResponseCode, setInputResponseCode] = React.useState<{ open: boolean, voucher?: IVoucher, minNrParticipant?: number }>({ open: false });

    const handleCheckCode = async (code: string) => {
        try {
            setIsLoading(true);

            const { voucher, controlCodeRequired, minNrParticipant } = await APIService.checkPromotionCode(code, selectedCourse.id);

            setIsLoading(false);

            if (!voucher.isRedeemed) {
                if (controlCodeRequired) {
                    setInputResponseCode({ open: true, voucher, minNrParticipant });
                } else {
                    onAddCode(voucher, minNrParticipant);
                }
            } else {
                toast.error(`Ingevoerde code reeds verbruikt`);
            }
            
        }
        catch (ex: any) {

            setIsLoading(false);

            if (ex.message) {
                toast.error(ex.message);
            } else {
                toast.error(ex);
            }
        }
    }

    const handleAddCode = (controlCode: string) => {
        const { voucher, minNrParticipant } = inputResponseCode;
        if (!controlCode || !voucher) return;
        onAddCode({ ...voucher, controlCode }, minNrParticipant || 0 );
        setInputResponseCode({ open: false });
    };

    return (<>
        <AskInputResponseCodeDialog
            open={inputResponseCode.open}
            onCancel={() => setInputResponseCode({ open: false })}
            length={inputResponseCode.voucher?.lengthOfControlCode || 0}
            onCompleted={code => handleAddCode(code)} />

        <Card style={{
            margin: '5px',
            borderRadius: '10px'
        }}>
            <CardContent style={{ padding: '4px', display: 'flex', flexDirection: 'column', }}>
                <Typography component='div' style={{ fontWeight: 600 }} >
                    Voer hier uw kortingscode in
                </Typography>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <TextField
                        id="outlined-basic"
                        label="Vouchercode"
                        variant="outlined"
                        margin="dense"
                        autoComplete="off"
                        value={inputCode}
                        onChange={(e) => setInputCode(e.target.value)}
                        InputProps={{ classes: { root: classes.inputRoot } }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused
                            }
                        }}
                        style={{
                            flex: 1,
                            marginRight: '5px'
                        }}
                    />
                    <div className={classes.wrapper}>
                        <Button
                            disabled={isLoading || disabled}
                            size='small'
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                handleCheckCode(inputCode);
                                setInputCode('');
                            }} >Bevestig</Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </div>

                {data.map(item => <PromotionItem key={item.id} data={item} onRemove={() => onRemoveCode(item.id)} />)}
            </CardContent>
        </Card>
    </>);
}

export default SectionPromotionCode;
