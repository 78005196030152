import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function GolfesContactDialog({
  open,
  fullScreen,
  onClose,
}: {
  open: boolean,
  onClose: () => void,
} & DialogProps) {

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>Contact</DialogTitle>
        <DialogContent dividers>
          <div style={{
            width: '100%'
          }}>
            <a rel="noreferrer" href="https://www.google.nl/maps/place/Frankfurtstraat+41,+1175+RH+Lijnden/@52.3494695,4.752315,15z/data=!4m5!3m4!1s0x47c5e3ffd8dd7c03:0xc443ee628cfba0e3!8m2!3d52.3509342!4d4.7613272" target="_blank">
              <img style={{ maxWidth: '100%' }} src="https://golflesnederland.nl/wp-content/themes/golflesnl/images/golflesnederland-map.jpg" alt="golflesnederland map" />
            </a>
          </div>


          <h4>Golfles Nederland</h4>
          <div>Spaarne 55-1</div>
          <div>2011 CE Haarlem</div>
          <div>Nederland</div>
          <div>T: +31 20 4496093</div>
          <div>Email:<a href="mailto:info@golflesnederland.nl">info@golflesnederland.nl</a></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
