import React from 'react';
import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from '@material-ui/icons/Close';

import { LocationSelection, useLocationSelection } from './LocationSelection';
import { CourseMultipleSelection, useCourseSelection } from './CourseSelection';
import { ParticipantSelection } from './InputParticipants';
import { ICourseFilterOption } from '../../models/courseFilter';
import { ICourse } from '../../models/course';
import { ILocation } from '../../models/location';
import { SlideTransition } from '../layout/Transitions';

const useStyles = makeStyles({
    root: {

        minWidth: 275,
        border: '1px lightgrey  solid',
        borderRadius: '10px',
        margin: '10px'
    },
});

type SearchType = 'location' | 'course' | 'participant';

const SearchFilter = ({
    filter = {
        courses: [],
        locations: [],
        over: 0,
        under: 0,
        showOnlyAvailableSlot: true,
    },
    onChangeNr = () => { },
    onChangeCourse = () => { },
    onChangeLocations = () => { },
}: {
    filter?: ICourseFilterOption,
    onChangeNr?: (over: number, under: number) => void,
    onChangeCourse?: (items: ICourse[]) => void,
    onChangeLocations?: (items: ILocation[]) => void,
}) => {
    const classes = useStyles();

    const courseState = useCourseSelection();

    const locationState = useLocationSelection();

    const [filterState, setFilterState] = React.useState<{
        type?: SearchType,
        open: boolean,
        over: number,
        under: number,
    }>({
        open: false,
        over: filter.over,
        under: filter.under,
    });

    const handleClickOpen = (type: SearchType) => {
        setFilterState(c => ({ ...c, type, open: true, over: filter.over, under: filter.under }));
        courseState.initCheck(filter.courses.map(i => i.id));
        locationState.initCheck(filter.locations.map(i => i.id));
    };

    const handleClose = () => {
        setFilterState(c => ({ ...c, open: false }));
    };

    const handleChangeCourses = () => {
        const items = courseState.checked;
        const selectedItems = courseState.data.filter(i => items.includes(i.id));
        onChangeCourse(selectedItems);
        handleClose();
    }

    const handleChangeLocations = () => {
        const items = locationState.checked;
        const selectedItems = locationState.data.filter(i => items.includes(i.id));
        onChangeLocations(selectedItems);
        handleClose();
    }

    const locationFilterString = filter
        && filter.locations
        && filter.locations.length > 0
        && (filter.locations.length === 1
            ? filter.locations[0].name
            : `${filter.locations[0].name} en ${filter.locations.length - 1} andere`);

    const courseFilterString = filter
        && filter.courses
        && filter.courses.length > 0
        && (filter.courses.length === 1
            ? filter.courses[0].name
            : `${filter.courses[0].name} en ${filter.courses.length - 1} andere`);

    return (<>
        <Dialog
            fullScreen open={filterState.open}
            TransitionComponent={SlideTransition}
            disableBackdropClick
        >
            <DialogTitle style={{ padding: '5px' }}>
                <IconButton onClick={handleClose} style={{ padding: '5px' }}>
                    <CloseIcon style={{ fontSize: '20px' }} />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers style={{
                minWidth: '300px',
                minHeight: '30px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}>
                {filterState.type === 'course'
                    && <CourseMultipleSelection
                        isLoading={courseState.getLoading}
                        data={courseState.data}
                        checkedItems={courseState.checked}
                        onItemSelected={courseState.handleToggle}
                        button={<Button variant="contained" color="primary" fullWidth onClick={handleChangeCourses}>Bijwerken</Button>}
                    />
                }

                {filterState.type === 'location'
                    && <LocationSelection
                        isLoading={locationState.getLoading}
                        data={locationState.data}
                        checkedItems={locationState.checked}
                        onItemSelected={locationState.handleToggle}
                        button={<Button variant="contained" color="primary" fullWidth onClick={handleChangeLocations}>Bijwerken</Button>}
                    />
                }

                {filterState.type === 'participant'
                    && <ParticipantSelection
                        over={filterState.over}
                        under={filterState.under}
                        onChange={(overValue, underValue) => setFilterState(c => ({ ...c, over: overValue, under: underValue, }))}
                        button={<Button variant="contained" color="primary" fullWidth onClick={() => { onChangeNr(filterState.over, filterState.under); handleClose() }}>Bijwerken</Button>}
                    />
                }
            </DialogContent>
        </Dialog>

        <Card className={classes.root}>
            <CardContent style={{
                padding: '5px'
            }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    role="presentation"
                    onClick={() => handleClickOpen('location')}
                >
                    <IconButton style={{ padding: '5px' }} >
                        <SearchIcon style={{ fontSize: '20px' }} />
                    </IconButton>

                    <Typography
                        component="div" variant="body2" style={{ fontWeight: 'bold', paddingLeft: '5px' }}
                        onClick={() => handleClickOpen('location')}
                    >
                        {locationFilterString || `alle`}
                    </Typography>

                </div>
                <Divider light />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    role="presentation"
                    onClick={() => handleClickOpen('course')}
                >
                    <IconButton style={{ padding: '5px', }} >
                        <CalendarTodayIcon style={{ fontSize: '20px' }} />
                    </IconButton>
                    <Typography
                        component="div" variant="body2" style={{ paddingLeft: '5px' }}
                    >
                        {courseFilterString || `alle`}
                    </Typography>

                </div>
                <Divider light />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        cursor: 'pointer'
                    }}
                    role="presentation"
                    onClick={() => handleClickOpen('participant')}
                >
                    <IconButton
                        style={{
                            padding: '5px'
                        }}
                    >
                        <GroupIcon style={{ fontSize: '20px' }} />
                    </IconButton>

                    <Typography
                        component="div" variant="body2" style={{ paddingLeft: '5px' }}
                    >
                        {`${filter.over + filter.under} deelnemers`}
                    </Typography>

                </div>
            </CardContent>
        </Card >
    </>);
}

export default SearchFilter;