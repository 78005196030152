import { ICourseFilterOption } from "../../models/courseFilter";
import { ActionMap } from "../utils";
import { ISearchResult, ISearchResultData } from '../../models/search';
import { ICourse } from "../../models/course";
import { ILocation } from "../../models/location";
import { IPage } from "../../models/pagination";

export enum ActionType {
    SEARCH_REQUEST = 'FILTER/SEARCH_REQUEST_REQUEST',
    SEARCH_COMPLETED = 'FILTER/SEARCH_REQUEST_COMPLETED',
    SET_COURSE_FILTER = 'FILTER/SET_COURSE_FILTER',
    SET_LOCATION_FILTER = 'FILTER/SET_LOCATION_FILTER',
    SET_PARTICIPANT = 'FILTER/SET_NR_OVER',
    SET_ONLY_AVAILABLE = 'FILTER/SET_ONLY_AVAILABLE',
    ADD_FILTER_REQUEST = 'FILTER/ADD_FILTER_REQUEST',
    SET_EXTRA_FILTER_DATE = 'FILTER/SET_EXTRA_FILTER_DATE',
    REMOVE_EXTRA_FILTER_DATE = 'FILTER/REMOVE_EXTRA_FILTER_DATE',
    SET_EXTRA_VENDOR_ID = 'FILTER/SET_EXTRA_VENDOR_ID',
    LOAD_MORE_REQUEST = 'FILTER/LOAD_MORE_REQUEST',
    LOAD_MORE_COMPLETED = 'FILTER/LOAD_MORE_COMPLETED',
    CLEAR = 'FILTER/CLEAR',
}

export type Payload = {
    [ActionType.SEARCH_REQUEST]: undefined;
    [ActionType.SEARCH_COMPLETED]: ISearchResult;
    [ActionType.LOAD_MORE_REQUEST]: undefined;
    [ActionType.LOAD_MORE_COMPLETED]: IPage<ISearchResultData>;
    [ActionType.ADD_FILTER_REQUEST]: undefined;
    [ActionType.SET_COURSE_FILTER]: ICourse[];
    [ActionType.SET_LOCATION_FILTER]: ILocation[];
    [ActionType.SET_PARTICIPANT]: {
        over: number,
        under: number,
    };
    [ActionType.SET_ONLY_AVAILABLE]: boolean;
    [ActionType.SET_EXTRA_FILTER_DATE]: {
        date: Date,
        data: IPage<ISearchResultData>;
    };
    [ActionType.REMOVE_EXTRA_FILTER_DATE]: undefined;
    [ActionType.SET_EXTRA_VENDOR_ID]: string;
    [ActionType.CLEAR]: undefined;
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export interface IState {
    loading: {
        [key: string]: boolean;
    };
    result: ISearchResult & { data: ISearchResultData[], filterData: ISearchResultData[], hasMore: boolean };
    filter: ICourseFilterOption;
    extra: {
        filterDate?: Date,
        vendorId?: string,
    }
}

export const initialState: IState = {
    loading: {},
    result: {
        availableDates: [],
        total: 0,
        page: {
            page: 0,
            pageSize: 0,
            total: 0,
            data: [],
        },
        hasMore: false,
        data: [],
        filterData: [],
    },
    filter: {
        courses: [],
        locations: [],
        over: 1,
        under: 0,
        showOnlyAvailableSlot: true,
    },
    extra: {}
}