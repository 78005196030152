import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Container, Hidden } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import SearchBar from '../search/SearchBar';
import { LayoutProps } from './Layout.props';
import { SmallMenu, CalendarItem } from '../search/SearchBarSmall';
import { LocationFilterDialog } from '../search/LocationSelection';
import { CourseFilterDialog } from '../search/CourseSelection';
import { NrParticipantFilterDialog } from '../search/InputParticipants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // '&::before': {
            //     content: '"some content"',
            //     display: 'block',
            //     height: 60,
            //     marginTop: -60
            // }
        },
        navContainer: {},
        navHeader: {
            height: '80px',
            left: '0px',
            width: '100%',
            zIndex: 100,
            position: 'fixed',
            background: 'rgb(255, 255, 255)',
            '&::before': {
                backgroundImage: 'linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))',
                content: '""',
                height: '140% ',
                position: 'absolute',
                left: '0px',
                top: '0px', // test, orgin is 0px
                opacity: 0,
                pointerEvents: 'none',
                transition: 'opacity 150ms ease 0s',
                width: '100%',
                zIndex: 0,

            },
            '&::after': {
                boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px',
                content: '""',
                height: '100%',
                position: 'absolute',
                left: '0px',
                top: '0px',
                opacity: 1,
                transformOrigin: '50% 0%',
                transition: 'opacity 150ms ease 0s, -ms-transform, -webkit-transform, transform',
                width: '100%',
                zIndex: 0,
                // background: 'rgb(255, 255, 255)',
            },
        },
        navContent: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            position: 'relative',
            width: '100%'
        },
        start: {
            flex: '0 0 auto',
            [theme.breakpoints.up('sm')]: {
                flex: '1 0 140px',
            },
        },
        middle: {
            flex: '0 1 auto',
            minWidth: '0px',
            padding: '0px 24px',
            textAlign: 'left',
            [theme.breakpoints.up('md')]: {
                textAlign: 'center',
            }
        },
        end: {
            flex: '1 0 auto',
            [theme.breakpoints.up('sm')]: {
                flex: '1 0 140px',
            },
        },
        lastItem: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '80px',
            WebkitBoxPack: 'end',
            WebkitBoxAlign: 'center',
        },
        breakSpace: {
            height: '80px',
        }
    }),
);

const AppHeader = ({ maxWidth, showCalendar = false }: Pick<LayoutProps, 'maxWidth'> & { showCalendar?: boolean }) => {
    const classes = useStyles();

    const history = useHistory();

    const [expanseSmallMenu, setExpanseSmallMenu] = React.useState(false);

    const [open, setOpen] = React.useState<{
        location: boolean,
        course: boolean,
        nr: boolean,
        expanse: boolean,
    }>({
        location: false,
        course: false,
        nr: false,
        expanse: false,
    });

    const backToHome = () => {
        history.push('/');
    }

    const openDialogLocation = () => setOpen(c => ({ ...c, location: true }));
    const openDialogCourse = () => setOpen(c => ({ ...c, course: true }));
    const openDialogGuest = () => setOpen(c => ({ ...c, nr: true }));

    return (<Box className={classes.root}>
        <Box className={classes.navContainer}>
            <header className={classes.navHeader}>
                <Container maxWidth={maxWidth} className={classes.navContent}>
                    <Box className={classes.start}>
                        <Link to="/">
                            <div className="nav--container--fixed__content__logo__link">
                                <div className="nav--container--fixed__content__logo__link--full-logo" style={{ backgroundImage: `url(${`/logo-horizontal.png`})` }}>{` `}</div>
                                <div className="nav--container--fixed__content__logo__link--small-logo" style={{ backgroundImage: `url(${`/logo-vertical.png`})` }}>{` `}</div>
                            </div>
                        </Link>
                    </Box>
                    <Box className={classes.middle}>
                        <SearchBar onHomeClick={backToHome} onLocationClick={openDialogLocation} onCourseClick={openDialogCourse} onGuestClick={openDialogGuest} />
                    </Box>
                    <Box className={classes.end}>
                        <nav className="nav--container--fixed__content__last__nav">
                            <Hidden mdUp>
                                { showCalendar && !expanseSmallMenu && <CalendarItem />}
                            </Hidden>
                            <SmallMenu expansed={expanseSmallMenu} onExpanseClick={() => setExpanseSmallMenu(true)} onColapseClick={() => setExpanseSmallMenu(false)} onHomeClick={backToHome} onLocationClick={openDialogLocation} onCourseClick={openDialogCourse} onGuestClick={openDialogGuest} />

                        </nav>
                    </Box>
                </Container>
            </header>
            <Box className={classes.breakSpace} />
        </Box>
        <>
            <LocationFilterDialog open={open.location} onClose={() => {
                setOpen(c => ({ ...c, location: false }));
            }} />
            <CourseFilterDialog open={open.course} onClose={() => {
                setOpen(c => ({ ...c, course: false }));
            }} />
            <NrParticipantFilterDialog open={open.nr} onClose={() => setOpen(c => ({ ...c, nr: false }))} />
        </>
    </Box>);
}

export default AppHeader;