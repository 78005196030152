import React from 'react';
import { Loader } from "@googlemaps/js-api-loader";

const initMap = (container: HTMLDivElement, mapOptions: google.maps.MapOptions, label: string): void => {
    // The map, centered at Uluru
    const map = new google.maps.Map(
        container,
        mapOptions,
    );

    // The marker, positioned at Uluru
    const marker = new google.maps.Marker({
        position: mapOptions.center,
        label
    });
    marker.setMap(map);
}

const GoogleMap = ({ style, lat, lng, zoom = 13, label }: { 
    style?: React.CSSProperties;
    lat: number;
    lng: number;
    zoom?: number;
    label: string;
}) => {
    const mapRef = React.useRef<HTMLDivElement>(null);
    const mapOption: google.maps.MapOptions = {
        center: {
            lat, lng
        },
        zoom,
    }
    // @ts-ignore google.maps.plugins
    const loader = new Loader({
        url: '/api/map/js',
        version: "weekly",
    });

    React.useEffect(() => {
        loader.load().then(() => initMap(mapRef.current!, mapOption, label))
    }, []);
    return <div style={style} ref={mapRef} />;
}

export default GoogleMap;