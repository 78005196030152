import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import LayoutDesktop from './layout/Layout.desktop';
import LayoutMobile from './layout/Layout.mobile';
import { useDevice } from '../hooks/device';


const Content = ({
    containerStyle
}: {
    containerStyle?: React.CSSProperties
}) => {

    const history = useHistory();
    return (<div style={containerStyle || {}}>
        <h1>{`Uw reservering is compleet. U ontvangt van ons een e-mail met alle boekingsinformatie. `}</h1>
        <Button onClick={() => history.replace('/')}>Terug naar website</Button>
    </div>)
}

const Congratulation = () => {
    const { isMobile } = useDevice();


    return (
        <>
            { isMobile
                ? <LayoutMobile>
                    <Content containerStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '5px'
                    }} />
                </LayoutMobile>
                : <LayoutDesktop>
                    <Content containerStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '5px'
                    }} />
                </LayoutDesktop>
            }
        </>);
}


export default Congratulation;
