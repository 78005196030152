import { ICourseProduct } from "../../models/course";
import { Reservation } from "../../models/reservation";
import { ISearchResultData } from "../../models/search";
import { IVoucher } from "../../models/voucherCode";
import { ActionMap } from "../utils"

export enum ActionType {
    GET_PLAN_REQUEST = 'RESERVATION/GET_PLAN_REQUEST',
    GET_PLAN_COMPLETE = 'RESERVATION/GET_PLAN_COMPLETE',
    SET_NR_OVER = 'RESERVATION/SET_NR_OVER',
    SET_NR_UNDER = 'RESERVATION/SET_NR_UNDER',
    SET_PRODUCTS = 'RESERVATION/SET_PRODUCTS',
    ADD_ADDITION_PRODUCT = 'RESERVATION/ADD_ADDITION_PRODUCT',
    UPDATE_ADDITION_PRODUCT_NR = 'RESERVATION/UPDATE_ADDITION_PRODUCT_NR',
    REMOVE_ADDITION_PRODUCT = 'RESERVATION/REMOVE_ADDITION_PRODUCT',

    ADD_PROMOTION_CODE = 'RESERVATION/ADD_PROMOTION_CODE',
    REMOVE_PROMOTION_CODE = 'RESERVATION/REMOVE_PROMOTION_CODE',

    CLEAR_ALL = 'RESERVATION/CLEAR_ALL',

    ADD_RESERVATION = 'RESERVATION/ADD_RESERVATION',
    UPDATE_RESERVATION = 'RESERVATION/UPDATE_RESERVATION',

    SUBMIT_REQUEST = 'RESERVATION/SUBMIT_REQUEST',
    SUBMIT_COMPLETE = 'RESERVATION/SUBMIT_COMPLETE',
}

export type Payload = {
    [ActionType.GET_PLAN_REQUEST]: undefined;
    [ActionType.GET_PLAN_COMPLETE]: ISearchResultData | undefined;
    [ActionType.SET_NR_OVER]: number;
    [ActionType.SET_NR_UNDER]: number;
    [ActionType.SET_PRODUCTS]: (ICourseProduct & { nr: number })[];
    [ActionType.ADD_ADDITION_PRODUCT]: ICourseProduct;
    [ActionType.UPDATE_ADDITION_PRODUCT_NR]: {
        productId: string,
        nr: number,
    };
    [ActionType.REMOVE_ADDITION_PRODUCT]: string;
    [ActionType.ADD_PROMOTION_CODE]: {
        voucher: IVoucher,
        minNrParticipant: number,
    };
    [ActionType.REMOVE_PROMOTION_CODE]: string;

    [ActionType.CLEAR_ALL]: {
        plan?: ISearchResultData,
        nrOver18: number,
        nrUnder18: number,
        products: (ICourseProduct & { nr: number })[],
        promotionCodes: IVoucher[],
        data: Reservation[],
        minNrParticipant: number,
    };

    [ActionType.ADD_RESERVATION]: Reservation;
    [ActionType.UPDATE_RESERVATION]: {
        id: string,
        data: Reservation,
    };

    [ActionType.SUBMIT_REQUEST]: undefined;
    [ActionType.SUBMIT_COMPLETE]: undefined;
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export interface IState {
    loading: {
        [key: string]: boolean;
    };
    plan?: ISearchResultData,
    nrOver18: number,
    nrUnder18: number,
    products: (ICourseProduct & { nr: number })[],
    promotionCodes: IVoucher[],
    data: Reservation[],
    minNrParticipant: number,
}

export const initialState: IState = {
    loading: {},
    nrOver18: 0,
    nrUnder18: 0,
    products: [],
    promotionCodes: [],
    data: [],
    minNrParticipant: 0,
}
