import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { useFilterContext } from '../../store/index';
import { useQuery } from '../../hooks/routes';
import * as APIService from '../../api/golfbooking.service';
import { ICourse } from '../../models/course';
import { ILocation } from '../../models/location';
import LayoutMobile from '../layout/Layout.mobile';
import BookingWizard from '../wizard/BookingWizard';
import { CourseSelectionStep } from './CourseSelection';
import { LocationSelectionStep } from './LocationSelection';
import { NrParticipantSelectionStep } from './InputParticipants';
import { SearchResultStep } from './SearchResult';
import { useDevice } from '../../hooks/device';
import HomePage from './Home';
import LayoutDesktop from '../layout/Layout.desktop';

interface SearchWizardProps {
    init: number,
}

const Mobile = ({ init }: SearchWizardProps) => (<LayoutMobile>
        <BookingWizard init={init}>
            <CourseSelectionStep step={1} title="Welke cursus wil je boeken?" />
            <LocationSelectionStep step={2} title="Welke omgeving heeft je voorkeur?" />
            <NrParticipantSelectionStep step={3} title="Wie komen er?" />
            <SearchResultStep step={4} />
        </BookingWizard>
    </LayoutMobile>);

const Desktop = () => (<LayoutDesktop maxWidth='lg' header={{ showCalendar: true }}>
        <HomePage />
    </LayoutDesktop>)

const Search = () => {
    const { isMobile } = useDevice();

    const query = useQuery();

    const { state: { result }, setFilterCourse, setFilterLocation, search, setExtraVendorId, setNrParticipant } = useFilterContext();

    const [searchState, setSearchState] = React.useState<{
        isLoading: boolean,
        initCourse?: ICourse,
        initLocation?: ILocation,
    }>({
        isLoading: false,
    });

    const { isLoading, initCourse, initLocation } = searchState;

    const load = async () => {
        setSearchState(c => ({ ...c, isLoading: true }));

        let initCourseResp: ICourse | undefined;
        let initLocationResp: ILocation | undefined;
        const courseTypeParam = query.get('course_type');
        if (courseTypeParam) {
            try {
                initCourseResp = await APIService.getCourseById(courseTypeParam);
            } catch (ex) {
                // TODO: handle error
            }
        };

        const locationParam = query.get('location');
        if (locationParam) {
            try {
                initLocationResp = await APIService.geLocationById(locationParam);
            }
            catch (ex) {
                // TODO: handle error
            }
        }

        const vendorParam = query.get('vendor');
        if (vendorParam) {
            setExtraVendorId(vendorParam);
        }

        const nrParam = query.get('nr');
        if ( nrParam && !Number.isNaN(+nrParam)) {
            if(Number.isInteger(+nrParam)) {
                setNrParticipant(+nrParam, 0);
            }
        }

        setSearchState(c => ({ ...c, isLoading: false, initLocation: initLocationResp, initCourse: initCourseResp }));

        if (initLocationResp) {
            setFilterLocation([initLocationResp]);
        }

        if (initCourseResp) {
            setFilterCourse([initCourseResp]);
        }

        await search();
    }

    React.useEffect(() => { load(); }, []);

    const mobileComp = () => isLoading
    ? <LayoutMobile>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
        </div>
    </LayoutMobile>
    : <Mobile init={(initLocation || initCourse || (result && result.total > 0)) ? 4 : 1} />;

    return (<>
        { !isLoading && isMobile
            ? mobileComp()
            : <Desktop />

        }
    </>
    );
}

export default Search;