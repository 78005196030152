import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Provider } from 'react-redux';
import 'moment/locale/nl';

import theme from './theme';
import CheckoutSummary from './components/checkout-summary/CheckoutSummary';
import Checkout from './components/checkout/Checkout';
import Congratulation from './components/Congratulation';
import { configureStore } from './store/index';
import Payment from './components/payment/Payment';
import Search from './components/search/Search';
import { ToastContainer } from './components/layout/Notification';
import { useDevice } from './hooks/device';
import TypographyDemo from './components/TypographyDemo';

function App() {
  const store = configureStore();

  React.useEffect(() => {
    moment.locale('nl');
  }, []);

  const { BrowserView, MobileView } = useDevice();

  return (
    <Provider store={store}>
      <BrowserView>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserView>

      <MobileView>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </MobileView>

      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path={["/", "/search"]}>
              <Search />
            </Route>

            <Route path="/summary">
              <CheckoutSummary />
            </Route>

            <Route path="/checkout">
              <Checkout />
            </Route>

            <Route path="/payment">
              <Payment />
            </Route>

            <Route path="/configuration">
              <Congratulation />
            </Route>

            <Route path="/typography">
              <TypographyDemo />
            </Route>

            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch>

        </Router>
      </ThemeProvider>

    </Provider>

  )
}

export default App;
