import { Dispatch } from 'redux';
import * as APIService from '../../api/golfbooking.service';
import { ICourseProduct } from '../../models/course';
import { ISearchResultData } from '../../models/search';
import { IVoucher } from '../../models/voucherCode';
import { Reservation } from '../../models/reservation';
import * as Model from './model';
import { toast } from '../../components/layout/Notification';


export const prepare = (
    plan: string | ISearchResultData,
    nrOver: number,
    nrUnder: number,
    vendorId?: string,
    callback?: () => void,
) => async (dispatch: Dispatch<Model.Actions>, getState: () => { reservation: Model.IState }) => {

    const { reservation: { promotionCodes, minNrParticipant: minP } } = getState();

    let choosePlan: ISearchResultData | undefined;

    if (typeof plan === 'string') {
        try {
            const data = await APIService.getPlanningById(plan);

            choosePlan = data;

        } catch (error: any) {
            toast.error('UNKNOWN ERROR: Cannot fetch data');
        }
    } else {
        choosePlan = plan;
    }

    if (choosePlan) {
        const { course: { products } } = choosePlan;

        let selectedCodes = [...promotionCodes];
        let minNrParticipant = minP;

        if (!vendorId) {
            selectedCodes = [];
            minNrParticipant = 0;
        }

        if (vendorId && promotionCodes.length === 0) {
            try {
                const res = await APIService.generatePromoteCode(vendorId);
                if (promotionCodes.filter(item => item.id === res.voucher.id).length === 0) {
                    selectedCodes = [...promotionCodes, res.voucher];

                    if (minNrParticipant < res.minNrParticipant) {
                        minNrParticipant = res.minNrParticipant;
                    }

                }
            } catch (ex: any) {
                if (ex.message) {
                    toast.error(ex.message);
                } else {
                    toast.error(ex);
                }
            }
        }

        dispatch({
            type: Model.ActionType.CLEAR_ALL,
            payload: {
                plan: choosePlan,
                nrOver18: nrOver,
                nrUnder18: nrUnder,
                products: [...products.filter(i => !i.isAddon).map(i => ({ ...i, nr: nrOver + nrUnder } as (ICourseProduct & { nr: number })))],
                promotionCodes: [...selectedCodes],
                data: [],
                minNrParticipant
            }
        });

        if (callback) {
            callback();
        }
    }


}

export const changeNrParticipants = (
    over: number, under: number,
) => (dispatch: Dispatch<Model.Actions>) => {
    if (over < 0 || under < 0) return;
    dispatch({ type: Model.ActionType.SET_NR_OVER, payload: over });
    dispatch({ type: Model.ActionType.SET_NR_UNDER, payload: under });
}

export const addAdditionProduct = (product: ICourseProduct) => (dispatch: Dispatch<Model.Actions>, getState: () => { reservation: Model.IState }) => {
    if (!product) return;
    const { reservation: { products } } = getState();

    if (products.filter(item => item.productId === product.productId).length > 0) return;
    dispatch({ type: Model.ActionType.ADD_ADDITION_PRODUCT, payload: product });
}

export const updateAdditionProductNr = (productId: string, nr: number) => (dispatch: Dispatch<Model.Actions>) => {
    if (!productId) return;

    if (nr <= 0) {
        dispatch({
            type: Model.ActionType.REMOVE_ADDITION_PRODUCT,
            payload: productId,
        });
    } else {
        dispatch({
            type: Model.ActionType.UPDATE_ADDITION_PRODUCT_NR,
            payload: {
                productId, nr,
            },
        });
    }
}

export const addPromotionCode = (code: IVoucher, minNrParticipant: number) => (dispatch: Dispatch<Model.Actions>, getState: () => { reservation: Model.IState }) => {
    const { reservation: { promotionCodes, minNrParticipant: minNr } } = getState();

    if (promotionCodes.filter(item => item.id === code.id).length > 0) return;

    if (minNr > 1) {
        toast.error('Maak een aparte reservering: de codes kunnen niet samen worden gebruikt.');
        return;
    }

    if (minNrParticipant > 1 && promotionCodes.length > 0) {
        toast.error('Maak een aparte reservering: de codes kunnen niet samen worden gebruikt.');
        return;
    }

    dispatch({
        type: Model.ActionType.ADD_PROMOTION_CODE,
        payload: {
            voucher: code,
            minNrParticipant,
        },
    });
}

export const removePromotionCode = (voucherId: string) => (dispatch: Dispatch<Model.Actions>) => {
    dispatch({
        type: Model.ActionType.REMOVE_PROMOTION_CODE,
        payload: voucherId,
    });
}

export const clear = () => (dispatch: Dispatch<Model.Actions>) => {
    dispatch({
        type: Model.ActionType.CLEAR_ALL,
        payload: {
            plan: undefined,
            nrOver18: 0,
            nrUnder18: 0,
            products: [],
            promotionCodes: [],
            data: [],
            minNrParticipant: 0,
        }
    });
}

export const addReservation = (data: Reservation) => async (dispatch: Dispatch<Model.Actions>, getState: () => { reservation: Model.IState }) => {
    const { reservation: { data: dataArray, plan } } = getState();

    if (plan) {
        dispatch({
            type: Model.ActionType.ADD_RESERVATION,
            payload: { ...data, id: `${-(dataArray.length + 1)}`, planningId: plan.id, addonProducts: [], promotionCodes: [] },
        });
    }
}

export const updateReservation = (id: string, data: Reservation) => async (dispatch: Dispatch<Model.Actions>) => {
    dispatch({
        type: Model.ActionType.UPDATE_RESERVATION,
        payload: {
            id, data,
        },
    });
}

export const paymentForOne = (id: string, iban: string, ibanName: string, paymentAmount: number) => async (dispatch: Dispatch<Model.Actions>, getState: () => { reservation: Model.IState }) => {
    const { reservation: { loading: { submit }, products, promotionCodes, data } } = getState();

    if (submit) return;

    dispatch({
        type: Model.ActionType.SUBMIT_REQUEST,
    });

    let existing = data.filter(i => i.id === id).map(i => ({
        ...i,
        iban,
        ibanName,
        paymentAmount,
        promotionCodes: [...promotionCodes],
    }))[0];

    let remainArray: Reservation[] = data.filter(i => i.id !== id).map(i => ({
        ...i,
        refIban: iban,
        refIbanName: ibanName,
        promotionCodes: [...promotionCodes],
    }));

    products.filter(item => item.isAddon).forEach(({ nr, ...product }) => {
        let count = 0;

        const calRemainArray = (c: number, array: Reservation[]) => array.map((i, index) => {
            if (index === c - 1) {
                return {
                    ...i,
                    addonProducts: [product]
                };
            }

            return i;
        })

        do {
            if (count === 0) {
                existing = {
                    ...existing,
                    addonProducts: [product]
                }
            }

            remainArray = calRemainArray(count, remainArray);

            count += 1;
        } while (count < nr);
    });

    try {
        await APIService.registerBooking([existing, ...remainArray]);
    } catch (ex: any) {
        if (ex.isAxiosError) {
            const { serverError } = ex;
            if (serverError) {
                toast.error(`Cannot booking. Error: ${serverError}`);
            } else {
                toast.error('Cannot booking, UNKNOWN ERROR');
            }
        } else {
            toast.error('Cannot booking, UNKNOWN ERROR');
        }
    }
    finally {
        dispatch({
            type: Model.ActionType.SUBMIT_COMPLETE,
        });

        dispatch({
            type: Model.ActionType.CLEAR_ALL,
            payload: {
                plan: undefined,
                nrOver18: 0,
                nrUnder18: 0,
                products: [],
                promotionCodes: [],
                data: [],
                minNrParticipant: 0,
            }
        });
    }

}

export const paymentForAll = (dataArray: Reservation[]) => async (dispatch: Dispatch<Model.Actions>, getState: () => { reservation: Model.IState }) => {
    const { reservation: { loading: { submit }, promotionCodes, data } } = getState();

    if (submit) return;

    dispatch({
        type: Model.ActionType.SUBMIT_REQUEST,
    });

    const updateList = data.map(item => {
        const newChanges = dataArray.find(i => i.id === item.id);

        if (newChanges) {
            const { iban, ibanName, paymentAmount, addonProducts, } = newChanges;

            return {
                ...item,
                iban,
                ibanName,
                paymentAmount,
                addonProducts: [...addonProducts],
                promotionCodes: [...promotionCodes]
            }

        }

        return item;

    });

    try {
        await APIService.registerBooking(updateList);
    } catch (ex) {
        toast.error('UNKNOWN ERROR: Cannot booking');
    }
    finally {
        dispatch({
            type: Model.ActionType.SUBMIT_COMPLETE,
        });

        dispatch({
            type: Model.ActionType.CLEAR_ALL,
            payload: {
                plan: undefined,
                nrOver18: 0,
                nrUnder18: 0,
                products: [],
                promotionCodes: [],
                data: [],
                minNrParticipant: 0,
            }
        });
    }
}
