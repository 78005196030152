import { ICourse } from "../../models/course";
import { ActionMap } from "../utils"

export enum ActionType {
    GET_LIST_REQUEST = 'COURSES/GET_LIST_REQUEST',
    GET_LIST_COMPLETED = 'COURSES/GET_LIST_COMPLETED',
}

export type Payload = {
    [ActionType.GET_LIST_REQUEST]: undefined;
    [ActionType.GET_LIST_COMPLETED]: {
        data: any[]
    };
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export interface IState {
    loading: {
        [key: string]: boolean;
    };
    list: ICourse[];
}

export const initialState: IState = {
    loading: {},
    list: [],
}